/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PagingQuery, Table, TableColumn } from 'tea-component';
import { Link, useRouteMatch } from 'react-router-dom';
import { formatDate } from 'src/utils/date';
import { IChainBrowserContractItem } from 'src/common/apis/chains/interface';
import { useFetchBrowserContractList } from 'src/common/apis/chains/hooks';
import { PAGE_SIZE_OPTIONS, splitUrl } from 'src/utils/common';
import { ChainDetailContext } from '../chain-detail';

const { pageable, autotip } = Table.addons;

export function ContractTable() {
  const chainMode = splitUrl(location.search).get('chainMode');
  // const isPublic = chainMode === 'public';
  const { url } = useRouteMatch();
  const { chainId } = useContext(ChainDetailContext);
  const { list, totalCount, fetch } = useFetchBrowserContractList();
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const defaultColumn: TableColumn<IChainBrowserContractItem>[] = [
    {
      key: 'ContractName',
      header: '合约名称',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { ContractName, Id } = record;
        return <Link to={`${url}/details?type=contract&Id=${Id}&chainMode=${chainMode}`}>{ContractName}</Link>;
      },
    },
    {
      key: 'ContractVersion',
      header: '合约版本号',
    },
    {
      key: 'ContractOperator',
      header: '创建者',
      render: ({ ContractOperator, Addr }) => Addr || ContractOperator,
    },
    {
      key: 'TxNum',
      header: '交易数',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { TxNum } = record;
        return <>{TxNum}</>;
      },
    },
    {
      key: 'Timestamp',
      header: '创建时间',
      width: 150,
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { Timestamp } = record;
        return <>{formatDate(Timestamp)}</>;
      },
    },
  ];

  const fetchList = useCallback(() => {
    if (!chainId) return;
    fetch({
      ChainId: chainId,
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
    });
  }, [fetch, pageQuery]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>
      <Table
        recordKey="Id"
        records={list}
        columns={defaultColumn}
        addons={[
          pageable({
            recordCount: totalCount,
            pageIndex: pageQuery.pageIndex,
            pageSize: pageQuery.pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            onPagingChange: ({ pageIndex, pageSize }) =>
              setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
          }),
          autotip({
            emptyText: '暂无数据',
          }),
        ]}
      />
    </>
  );
}
