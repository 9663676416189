/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect, useRef } from 'react';
import { Button, Form, Input, Modal, Select } from 'tea-component';
import { Control, Controller, useForm, useWatch } from 'react-hook-form';
import formUtils, { Validator } from 'src/utils/form-utils';
import { useFetchImportCert } from 'src/common/apis/certificates/hooks';
import { UploadFile } from 'src/common/components/upload-file';
import { CertOrg, ChainAlgorithm, ICertItem } from '../../../../common/apis/certificates/interface';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { SizeType } from 'tea-component/lib/_type/Size';

const { getStatus } = formUtils;

interface IImportCertModalParam {
  visible: boolean;
  close: () => void;
  successCallback?: () => void;
}

const initialData = {
  OrgId: '',
  OrgName: '',
};

export function ImportOrgCertModal({ visible, close, successCallback }: IImportCertModalParam) {
  const caRef = useRef<any>(null);
  const keyRef = useRef<any>(null);
  const { fetch } = useFetchImportCert();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValidating, isSubmitted, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Algorithm: ChainAlgorithm.CN.toString(),
    } as any,
  });

  const submit = async () => {
    const { OrgId, OrgName, CaCert, CaKey, Algorithm } = getValues();
    await fetch({
      Type: 0,
      OrgId,
      OrgName,
      CaCert,
      CaKey,
      Algorithm: +Algorithm as ICertItem['Algorithm'],
      ChainMode: 'permissionedWithCert',
    });
    successCallback?.();
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  return (
    <Modal caption="导入组织证书" visible={visible} onClose={close}>
      <Modal.Body>
        <Form>
          <Controller
            name="OrgId"
            defaultValue={initialData.OrgId}
            control={control}
            rules={{
              validate: (OrgId) => Validator.validateOrgId(OrgId),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="组织ID"
                required
                status={getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.OrgId?.message}
              >
                <Input placeholder="请输入组织ID" size="full" {...field} />
              </Form.Item>
            )}
          />
          <Controller
            name="OrgName"
            defaultValue={initialData.OrgName}
            control={control}
            rules={{
              validate: (OrgName) => Validator.validateOrgName(OrgName),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="组织名称"
                required
                status={getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.OrgName?.message}
              >
                <Input placeholder="请输入组织名称" size="full" {...field} />
              </Form.Item>
            )}
          />
          {renderChainAlgorithmFormItem(control, isSubmitted, isValidating)}
          <Controller
            name="CaCert"
            control={control}
            rules={{
              validate: async (value) => {
                if (!value && caRef.current?.status === null) {
                  return '请上传组织CA证书';
                }
                if (!value && caRef.current?.status === 'error') {
                  return '上传组织CA证书格式不正确';
                }
                return undefined;
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item label="组织CA证书" required status={caRef.current?.status} message={fieldState.error?.message}>
                <UploadFile
                  accept="application/x-x509-ca-cert,.cer,.crt"
                  {...field}
                  ref={caRef}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
          <Controller
            name="CaKey"
            control={control}
            rules={{
              validate: async (value) => {
                if (!value && keyRef.current?.status === null) {
                  return '请上传组织CA私钥';
                }
                if (!value && keyRef.current?.status === 'error') {
                  return '上传组织CA私钥格式不正确';
                }
                return undefined;
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="组织CA私钥"
                required
                status={keyRef.current?.status}
                message={fieldState.error?.message}
              >
                <UploadFile
                  accept=".key"
                  {...field}
                  ref={keyRef}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={!isValid} onClick={handleSubmit(submit)}>
          确定
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function useUpdateAlgorithmByOrg(orgList: CertOrg[], control: Control, setValue: UseFormSetValue<any>) {
  const orgIdWatch = useWatch({ name: 'OrgId', control });
  useEffect(() => {
    const find = orgList.find((item) => item.OrgId === orgIdWatch);
    find && setValue('Algorithm', find.Algorithm);
  }, [orgIdWatch, orgList]);
}

export function renderChainAlgorithmFormItem(
  control: Control,
  isSubmitted: boolean,
  isValidating: boolean,
  disabled = false,
  size: SizeType | 'auto' = 'full',
) {
  return (
    <Controller
      name="Algorithm"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <Form.Item
          label="密码算法"
          required
          status={getStatus({ fieldState, isSubmitted, isValidating })}
          message={fieldState.error?.message}
        >
          <Select
            disabled={disabled}
            size={size}
            options={[
              {
                text: '国密',
                value: ChainAlgorithm.CN.toString(),
              },
              {
                text: '非国密',
                value: ChainAlgorithm.NOT_CN.toString(),
              },
            ]}
            {...field}
          />
        </Form.Item>
      )}
    />
  );
}
