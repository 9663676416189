/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useState, useCallback, useEffect } from 'react';
import { Layout, Justify, Card, Table, Button, SearchBox, TableColumn, PagingQuery, Copy } from 'tea-component';
import GlossaryGuide from 'src/common/components/glossary-guide';
import { ICertItem, ChainAlgorithm, IDetailCertParam } from 'src/common/apis/certificates/interface';
import { formatDate } from 'src/utils/date';
import { GeneratePublicModal } from './modals/generate-public-modal';
import { ImportPublicModal } from './modals/import-public-modal';
import { DetailPublicModal } from './modals/detail-public-modal';
import { useFetchCertList, useFetchRemoveCertDetail } from 'src/common/apis/certificates/hooks';
import { PAGE_SIZE_OPTIONS } from 'src/utils/common';
const { Content } = Layout;
const { pageable, autotip } = Table.addons;

const CERT_TYPE_MAP: {
  [index: number]: string;
} = {
  2: '用户账户',
  1: '节点账户',
};

export default function CertificatesPage() {
  const [generateModalVisible, setGenerateModalVisible] = useState<boolean>(false);
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [targetCert, setTargetCert] = useState<ICertItem | null>(null);
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueInput, setSearchValueInput] = useState<string>('');
  const { list, totalCount, fetch } = useFetchCertList();
  const { fetchRemoveCert } = useFetchRemoveCertDetail();

  const defaultColumns: TableColumn<ICertItem>[] = [
    {
      key: 'RemarkName',
      header: '账户备注名',
    },
    {
      key: 'Addr',
      header: <GlossaryGuide title="账户地址" />,
      width: '30%',
      render: function AddrColumn({ Addr }: ICertItem) {
        return (
          <span>
            {Addr}
            <Copy text={Addr} />
          </span>
        );
      },
    },
    {
      key: 'CertType',
      header: '用途',
      render: ({ CertType }) => CERT_TYPE_MAP[CertType],
    },
    {
      key: 'Algorithm',
      header: '密码算法',
      render: ({ Algorithm }) => (Algorithm === ChainAlgorithm.CN ? '国密' : '非国密'),
    },
    {
      key: 'CreateTime',
      header: '创建时间',
      render: ({ CreateTime }) => formatDate(CreateTime),
    },
    {
      key: 'Action',
      header: '操作',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setTargetCert(record);
              setDetailModalVisible(true);
            }}
          >
            查看
          </Button>
          <Button
            type="link"
            onClick={() => {
              fetchDeleteCert({ CertId: record.Id });
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ];
  const fetchList = useCallback(() => {
    fetch({
      Type: 2,
      Addr: searchValue ?? '',
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
      ChainMode: 'public',
    });
  }, [fetch, searchValue, pageQuery]);

  const fetchDeleteCert = useCallback(
    (param: IDetailCertParam) => {
      fetchRemoveCert(param, true).then(() => {
        fetchList();
      });
    },
    [fetchList],
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);
  return (
    <>
      <Content.Header title={<GlossaryGuide title="公钥账户(Public Key)" />} />
      <Content.Body full>
        <GeneratePublicModal
          visible={generateModalVisible}
          close={() => setGenerateModalVisible(false)}
          successCallback={() => {
            fetchList();
            setGenerateModalVisible(false);
          }}
        />
        <ImportPublicModal
          visible={importModalVisible}
          close={() => setImportModalVisible(false)}
          successCallback={() => {
            fetchList();
            setImportModalVisible(false);
          }}
        />
        <DetailPublicModal
          visible={detailModalVisible}
          CertId={targetCert?.Id}
          close={() => setDetailModalVisible(false)}
        />
        <Justify
          left={
            <>
              <Button type="primary" onClick={() => setGenerateModalVisible(true)}>
                申请账户
              </Button>
              <Button type="primary" onClick={() => setImportModalVisible(true)}>
                导入账户
              </Button>
            </>
          }
          right={
            <SearchBox
              placeholder="输入账户地址搜索"
              size="l"
              value={searchValueInput}
              onChange={(value) => setSearchValueInput(value)}
              onSearch={(value) => {
                setSearchValue(value ?? '');
                setPageQuery({
                  ...pageQuery,
                  pageIndex: 1,
                });
              }}
              onClear={() => {
                setSearchValue('');
                setPageQuery({
                  ...pageQuery,
                  pageIndex: 1,
                });
              }}
            />
          }
        />
        <Card className="tea-mt-5n">
          <Table
            records={list}
            recordKey={(record) => `${record.Id}--${record.CertUse}`}
            columns={defaultColumns}
            addons={[
              pageable({
                recordCount: totalCount,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                pageIndex: pageQuery.pageIndex,
                pageSize: pageQuery.pageSize,
                onPagingChange: ({ pageIndex, pageSize }) =>
                  setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
              }),
              autotip({
                emptyText: '暂无数据',
                isFound: !!searchValue.length,
                onClear: () => {
                  setSearchValue('');
                  setSearchValueInput('');
                },
              }),
            ]}
          />
        </Card>
      </Content.Body>
    </>
  );
}
