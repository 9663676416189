/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect } from 'react';
import { Button, Form, Input, Modal, Select } from 'tea-component';
import { Controller, useForm } from 'react-hook-form';
import formUtils, { Validator } from 'src/utils/form-utils';
import { useFetchCertOrgList } from 'src/common/apis/chains/hooks';
import { useFetchGenerateCert } from 'src/common/apis/certificates/hooks';
import { ChainAlgorithm, ICertItem, IGenerateCertParam } from '../../../../common/apis/certificates/interface';
import {
  renderChainAlgorithmFormItem,
  useUpdateAlgorithmByOrg,
} from '../../organization-cert/modals/import-org-cert-modal';

const { getStatus } = formUtils;

interface IGenerateCertModalParam {
  visible: boolean;
  close: () => void;
  successCallback?: () => void;
}

interface IInitialData {
  UserName: string;
  OrgName: string;
  OrgId: string;
  UserRole: '0' | '1' | '2';
  Algorithm: string;
}

const initialData: IInitialData = {
  UserName: '',
  OrgName: '',
  OrgId: '',
  UserRole: '0',
  Algorithm: ChainAlgorithm.CN.toString(),
};

export function GenerateUserCertModal({ visible, close, successCallback }: IGenerateCertModalParam) {
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValidating, isSubmitting, isSubmitted, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const { orgList, run: fetchOrgList } = useFetchCertOrgList();
  const { fetch: generateCert } = useFetchGenerateCert();
  useUpdateAlgorithmByOrg(orgList, control, setValue);

  const submit = async (values: IInitialData) => {
    await generateCert({
      CertType: 2,
      ...values,
      UserRole: Number(values.UserRole) as IGenerateCertParam['UserRole'],
      Algorithm: +values.Algorithm as ICertItem['Algorithm'],
    });
    successCallback?.();
  };

  useEffect(() => {
    if (visible) {
      reset();
      fetchOrgList();
    }
  }, [visible]);

  return (
    <Modal caption="申请用户证书" visible={visible} onClose={close}>
      <Modal.Body>
        <Form>
          <Controller
            name="UserName"
            control={control}
            defaultValue={initialData.UserName}
            rules={{
              validate: (UserName) => Validator.validateUserName(UserName),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="用户名称"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.UserName?.message}
              >
                <Input placeholder="请输入用户名称" size="full" {...field} />
              </Form.Item>
            )}
          />
          <Controller
            name="OrgId"
            control={control}
            defaultValue={initialData.OrgId}
            rules={{
              validate: (OrgId) => Validator.validateOrgId(OrgId, 'select'),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="选择组织"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.OrgId?.message}
              >
                <Select
                  placeholder="请选择组织"
                  type="simulate"
                  appearance="button"
                  size="full"
                  {...field}
                  options={orgList.map((item) => ({
                    text: item.OrgName,
                    value: item.OrgId,
                  }))}
                  {...field}
                  onChange={(value) => {
                    setValue('OrgId', value);
                    setValue('OrgName', orgList.find((item) => item.OrgId === value)?.OrgName);
                  }}
                />
              </Form.Item>
            )}
          />
          {renderChainAlgorithmFormItem(control, isSubmitted, isValidating, true)}
          <Controller
            name="UserRole"
            control={control}
            defaultValue={initialData.UserRole}
            rules={{
              validate: (UserRole) => {
                if (UserRole === null || UserRole === undefined) {
                  return '请选择用户角色';
                }
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="用户角色"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.UserRole?.message}
              >
                <Select
                  placeholder="请选择用户角色"
                  type="simulate"
                  appearance="button"
                  options={[
                    {
                      value: '0',
                      text: 'admin',
                    },
                    {
                      value: '1',
                      text: 'client',
                    },
                    {
                      value: '2',
                      text: 'light',
                    },
                  ]}
                  size="full"
                  {...field}
                />
              </Form.Item>
            )}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={isSubmitting || !isValid} onClick={handleSubmit(submit)}>
          确定
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}
