/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Modal, Text } from 'tea-component';
import { ChainDetail, ChainReConfigRequest } from '../../common/apis/chains/interface';
import { Controller, useForm } from 'react-hook-form';
import formUtils, { Validator } from '../../utils/form-utils';
import { useFetchChainReConfig } from '../../common/apis/chains/hooks';

type Props = { chainDetail: ChainDetail; visible: boolean; onClose: () => void; onSubmit: () => void };

export function ChainReConfigModal(props: Props) {
  const [visible, setVisible] = useState(false);
  const { chainDetail } = props;
  const {
    control,
    reset,
    formState: { isValidating, isSubmitted, isValid },
    getValues,
  } = useForm({ mode: 'onBlur' });

  const onClose = useCallback(() => {
    setVisible(false);
    props.onClose();
    reset(chainDetail);
  }, [chainDetail]);

  const { run } = useFetchChainReConfig();
  const onSubmit = useCallback(async () => {
    await run({
      ...(getValues() as ChainReConfigRequest),
      ChainId: props.chainDetail.ChainId,
    });
    onClose();
  }, []);
  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <Modal visible={visible} caption="修改链配置" onClose={onClose}>
      <Modal.Body>
        <Form>
          <Form.Item label="区块链ID">
            <Text reset theme={'text'}>
              {chainDetail.ChainId}
            </Text>
          </Form.Item>
          <Form.Item label="区块链名称">
            <Text reset theme={'text'}>
              {chainDetail.ChainName}
            </Text>
          </Form.Item>
          <Controller
            control={control}
            rules={{
              validate: () => undefined,
            }}
            name="BlockTxCapacity"
            defaultValue={chainDetail.BlockTxCapacity}
            render={({ field, fieldState }) => (
              <Form.Item
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
                required
                label="区块最大容量"
              >
                <InputNumber
                  min={Validator.minBlockTxCapacity}
                  max={Validator.maxNumber}
                  unit={'笔交易'}
                  size={'l'}
                  {...field}
                />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name="BlockInterval"
            rules={{
              validate: () => undefined,
            }}
            defaultValue={chainDetail.BlockInterval}
            render={({ field, fieldState }) => (
              <Form.Item
                required
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
                label="出块间隔"
              >
                <InputNumber
                  min={Validator.minBlockInterval}
                  max={Validator.maxNumber}
                  unit={'ms'}
                  size={'l'}
                  {...field}
                />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name="TxTimeout"
            rules={{
              validate: () => undefined,
            }}
            defaultValue={chainDetail.TxTimeout}
            render={({ field, fieldState }) => (
              <Form.Item
                required
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
                label="交易过期时长"
              >
                <InputNumber min={Validator.minTxTimeout} max={Validator.maxNumber} unit={'s'} size={'l'} {...field} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name="Reason"
            rules={{
              validate: Validator.validateReason,
            }}
            defaultValue={chainDetail.Reason}
            render={({ field, fieldState }) => (
              <Form.Item
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
                label="修改理由"
              >
                <Input multiline size={'full'} placeholder={'修改需其他组织同意，请输入修改理由（选填）'} {...field} />
              </Form.Item>
            )}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" onClick={onSubmit} disabled={!isValid}>
          确定
        </Button>
        <Button type="weak" onClick={onClose}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
