import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { ChainModes } from 'src/common/apis/chains/interface';
import formUtils from 'src/utils/form-utils';
import { Form, H3, Justify } from 'tea-component';
import AdminSelector from './admin-selector';
export default function ManagerOp(props: {
  control: Control;
  selectedChainMode: ChainModes;
  isSubmitted: boolean;
  isValidating: boolean;
  algorithm?: string;
}) {
  return (
    <Form layout="fixed" fixedLabelWidth="90px">
      <Justify left={<H3>链管理员配置</H3>} className={'tea-mb-5n'} />
      <Controller
        control={props.control}
        name={'Admins'} // 临时属性，该字段并不提交后台
        defaultValue={[]}
        rules={{
          validate: (value: string[]) => {
            if (value.length === 0) {
              return '请最少选择一个管理员';
            }
            return undefined;
          },
        }}
        render={({ field, fieldState }) => (
          <Form.Item
            label="管理员"
            className={'node-selector'}
            status={formUtils.getStatus({
              fieldState,
              isSubmitted: props.isSubmitted,
              isValidating: props.isValidating,
            })}
            message={fieldState.error?.message}
          >
            <AdminSelector chainMode={props.selectedChainMode} algorithm={props.algorithm} onChange={field.onChange} />
          </Form.Item>
        )}
      />
    </Form>
  );
}
