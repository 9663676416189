/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useContext } from 'react';
import { Layout } from 'tea-component';
import { useQueryParams } from 'src/utils/hooks';
import { BlockDetail } from './block-detail';
import { DealDetail } from './deal-detail';
import { ContractDetail } from './contract-detail';
import { ChainDetailContext } from '../chain-detail';
import { useHistory } from 'react-router-dom';

const { Content } = Layout;
const DETAIL_PAGE_MAP = new Map([
  ['block', { component: BlockDetail, title: '区块详情' }],
  ['deal', { component: DealDetail, title: '交易详情' }],
  ['contract', { component: ContractDetail, title: '合约详情' }],
]);

export function DetailPage() {
  const history = useHistory();
  const { type, Id: id, txId } = useQueryParams<{ type: string; Id: string; txId: string }>();
  const { chainId } = useContext<{ chainId: string | null }>(ChainDetailContext);

  if (type && DETAIL_PAGE_MAP.has(type)) {
    const detail = DETAIL_PAGE_MAP.get(type);
    return (
      <>
        <Layout>
          <Content.Header
            showBackButton
            onBackButtonClick={history.goBack}
            title={`区块链管理/区块链浏览器/${detail!.title}`}
          />
          <Content.Body full>
            {type === 'block' && <BlockDetail id={Number(id)} chainId={chainId} />}
            {type === 'deal' && <DealDetail id={Number(id)} chainId={chainId} txId={txId} />}
            {type === 'contract' && <ContractDetail id={Number(id)} chainId={chainId} />}
          </Content.Body>
        </Layout>
      </>
    );
  }

  return <></>;
}
