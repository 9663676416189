/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, Justify, Layout, PagingQuery, SearchBox, Table, TableColumn } from 'tea-component';
import { Link, useRouteMatch } from 'react-router-dom';
import { ChainNodeTypeDescMap, IChainNodeItem } from 'src/common/apis/chains/interface';
import { PAGE_SIZE_OPTIONS, splitUrl } from 'src/utils/common';
import { useFetchChainNodeList } from 'src/common/apis/chains/hooks';
import { ChainDetailContext } from '../chain-detail';
import GlossaryGuide from '../../../common/components/glossary-guide';

const { Content } = Layout;
const { pageable, autotip } = Table.addons;

export function ChainNode() {
  const { url } = useRouteMatch();
  const { chainId } = useContext(ChainDetailContext);
  const { list, totalCount, fetch } = useFetchChainNodeList();
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueInput, setSearchValueInput] = useState<string>('');
  const chainMode = splitUrl(location.search).get('chainMode');
  let defaultColumn: TableColumn<IChainNodeItem>[] = [
    {
      key: 'NodeName',
      header: '节点名称',
    },
  ];
  if (chainMode !== 'public') {
    defaultColumn.push({
      key: 'OrgName',
      header: '所属组织',
    });
  }
  defaultColumn = defaultColumn.concat([
    {
      key: 'NodeType',
      header: '节点类型',
      render: (record) => ChainNodeTypeDescMap[record.NodeType],
    },
    {
      key: 'NodeId',
      header: <GlossaryGuide title={'节点ID'} />,
    },
    {
      key: 'NodeAddr',
      header: <GlossaryGuide title={'节点地址'} />,
      // eslint-disable-next-line react/display-name
      render: ({ NodeAddr, NodePort }: IChainNodeItem) => (
        <>
          {NodeAddr}:{NodePort}
        </>
      ),
    },
    {
      key: 'UpdateType',
      header: <GlossaryGuide title={'账本同步类型'} />,
    },
    {
      key: 'Action',
      header: '操作',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { Id, OrgNodeId } = record;
        return <Link to={`${url}/details/${Id}?chainMode=${chainMode}&OrgNodeId=${OrgNodeId}`}>查看</Link>;
      },
    },
  ]);

  const fetchList = useCallback(() => {
    if (!chainId) return;
    fetch({
      ChainId: chainId,
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
      NodeName: searchValue ?? '',
    });
  }, [chainId, pageQuery, searchValue, fetch]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>
      <Layout>
        <Content>
          <Content.Header title="区块链管理/节点信息" />
          <Content.Body full>
            <Justify
              right={
                <SearchBox
                  placeholder="请输入节点名搜索"
                  size="l"
                  value={searchValueInput}
                  onChange={(value) => setSearchValueInput(value)}
                  onSearch={(value) => setSearchValue(value ?? '')}
                  onClear={() => setSearchValue('')}
                />
              }
            />
            <Card className="tea-mt-5n">
              <Table
                recordKey="Id"
                records={list}
                columns={defaultColumn}
                addons={[
                  pageable({
                    recordCount: totalCount,
                    pageIndex: pageQuery.pageIndex,
                    pageSize: pageQuery.pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    onPagingChange: ({ pageIndex, pageSize }) =>
                      setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
                  }),
                  autotip({
                    emptyText: '暂无数据',
                    isFound: !!searchValue.length,
                    onClear: () => {
                      setSearchValue('');
                      setSearchValueInput('');
                    },
                  }),
                ]}
              />
            </Card>
          </Content.Body>
        </Content>
      </Layout>
    </>
  );
}
