/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Card, Form, Layout } from 'tea-component';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { GraphChart } from 'echarts/charts';
import { GraphicComponent, LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { ChainDetailContext } from '../chain-detail';
import { useFetchChainNodeDetail } from 'src/common/apis/chains/hooks';
// import { formatDate } from 'src/utils/date';
import { GenerateGraphOption } from './generate-graph';
import { ChainNodeTypeDescMap } from '../../../common/apis/chains/interface';
import { splitUrl } from 'src/utils/common';
const { Content } = Layout;

echarts.use([GraphChart, GraphicComponent, LegendComponent, CanvasRenderer]);

export function ChainNodeDetail() {
  const history = useHistory();
  const { id: nodeId } = useParams<{ id: string }>();
  const { chainId } = useContext(ChainDetailContext);
  const { detail, fetch } = useFetchChainNodeDetail();
  const [graphOption, setGraphOption] = useState<any>(null);
  const OrgNodeId = splitUrl(location.search).get('OrgNodeId');
  const chainMode = splitUrl(location.search).get('chainMode');
  useEffect(() => {
    if (!nodeId || !chainId) return;
    fetch({
      ChainId: chainId,
      NodeId: Number(nodeId),
      OrgNodeId: Number(OrgNodeId),
    });
  }, [nodeId, chainId, fetch]);

  useEffect(() => {
    if (detail?.NodeName) {
      setGraphOption(GenerateGraphOption({ NodeName: detail.NodeName, NodeList: detail.LinkNodeList }));
    }
  }, [detail]);
  return (
    <>
      <Layout>
        <Content>
          <Content.Header
            showBackButton
            title="区块链管理/节点信息/节点详情"
            onBackButtonClick={() => history.goBack()}
          />
          <Content.Body full>
            <Card>
              <Card bordered style={{ border: 'none' }}>
                <Card.Body title="节点基本信息">
                  <Form>
                    {chainMode !== 'public' && (
                      <Form.Item label="所属组织">
                        <Form.Text>{detail?.OrgName}</Form.Text>
                      </Form.Item>
                    )}
                    <Form.Item label="节点名称">
                      <Form.Text>{detail?.NodeName}</Form.Text>
                    </Form.Item>
                    {/* {
                      <Form.Item label="加入时间">
                        <Form.Text>{formatDate(detail?.CreateTime)}</Form.Text>
                      </Form.Item>
                    } */}
                    <Form.Item label="节点ID">
                      <Form.Text>{detail?.NodeId}</Form.Text>
                    </Form.Item>
                    <Form.Item label="节点IP和端口">
                      <Form.Text>
                        {detail?.NodeAddr}:{detail?.NodePort}
                      </Form.Text>
                    </Form.Item>
                    <Form.Item label="节点角色">
                      <Form.Text>{detail?.NodeType !== undefined && ChainNodeTypeDescMap[detail.NodeType]}</Form.Text>
                    </Form.Item>
                    <Form.Item label="账本同步方式">
                      <Form.Text>{detail?.UpdateType}</Form.Text>
                    </Form.Item>
                  </Form>
                </Card.Body>
              </Card>

              <Card bordered style={{ border: 'none' }} className="tea-mt-0">
                <Card.Body title="节点网络连接">
                  {graphOption && <ReactEChartsCore echarts={echarts} option={graphOption} style={{ height: 700 }} />}
                </Card.Body>
              </Card>
            </Card>
          </Content.Body>
        </Content>
      </Layout>
    </>
  );
}
