/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { AxiosResponse } from 'axios';
import { callApi } from '../api';
import { Response, UploadFileRequest } from '../interface';
import { API_URL } from '../../utils/request';

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E6%96%87%E4%BB%B6%E7%AE%A1%E7%90%86/%E4%B8%8A%E4%BC%A0%E6%96%87%E4%BB%B6.md
 * UploadFile
 */
export function fetchUploadFile(params: UploadFileRequest): Promise<AxiosResponse<Response<{ FileKey: string }>>> {
  return callApi('UploadFile', params);
}

export const UPLOAD_FILE_URL = `${API_URL}?cmb=UploadFile`;
