/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */

import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Text, Button, Card, Justify, Layout, PagingQuery, Table, TableColumn, Switch, Icon } from 'tea-component';
import {
  useFetchLogList,
  useFetchAutoReportLogFile,
  useFetchDownloadLogFile,
  useFetchReportLogFile,
  useFetchPullErrorLog,
} from 'src/common/apis/logs/hooks';
import { PAGE_SIZE_OPTIONS } from 'src/utils/common';
import { ILogInfo } from 'src/common/apis/logs/interface';
import ChainSelect from './components/chain-select';
import { formatDateTime } from 'src/utils/date';

const { Body, Content } = Layout;
const { addons } = Table;
const { pageable } = addons;
// 上报过程耗时较长，添加loading状态
interface Ilog extends ILogInfo {
  reporting: boolean;
}

export default function logsPage() {
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const [chainId, setChainId] = useState('');
  const [isAutoReport, setIsAutoReport] = useState(false);
  const chainSelectRef = useRef<any>(null);
  const { list, totalCount, fetch } = useFetchLogList();
  const [logs, setLogs] = useState<Ilog[]>([]);
  const { fetch: fetchDownload } = useFetchDownloadLogFile();
  const { fetch: fetchReportLog } = useFetchReportLogFile();
  const { fetch: fetchAutoReportLog } = useFetchAutoReportLogFile();
  const { fetch: fetchPullErrorLog } = useFetchPullErrorLog();
  const downLoadLog = useCallback(({ Id }) => {
    fetchDownload({
      Id,
    });
  }, []);
  const reportLog = useCallback(
    ({ Id }, Index) => {
      logs[Index].reporting = true;
      setLogs(logs.slice());
      fetchReportLog({ Id }).then(() => {
        if (logs[Index].Id === Id) {
          logs[Index].reporting = false;
          setLogs(logs.slice());
        }
      });
    },
    [logs],
  );

  const chainSelectRefresh = useCallback(() => {
    chainSelectRef.current.refreshList();
  }, [chainSelectRef]);

  const autoReport = useCallback(
    (value: boolean) => {
      fetchAutoReportLog({
        ChainId: chainId,
        Automatic: value ? 1 : 0,
      }).then(() => {
        chainSelectRefresh();
      });
    },
    [chainId, chainSelectRefresh],
  );

  const fetchList = useCallback(() => {
    if (!chainId) {
      return;
    }
    fetch({
      ChainId: chainId,
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
    });
  }, [fetch, pageQuery, chainId]);
  const refreshList = useCallback(() => {
    fetchPullErrorLog({
      ChainId: chainId,
    }).then(() => {
      fetchList();
    });
  }, [chainId]);
  useEffect(() => {
    fetchList();
  }, [pageQuery]);
  useEffect(() => {
    setPageQuery({
      pageSize: 10,
      pageIndex: 1,
    });
  }, [chainId]);
  useEffect(() => {
    setLogs(
      list.map((log) => ({
        ...log,
        reporting: false,
      })),
    );
  }, [list]);

  const onChangeChain = useCallback((value, context) => {
    setChainId(value);
    setIsAutoReport(Boolean(context.option.AutoReport));
  }, []);

  const defaultColumns: TableColumn<Ilog>[] = [
    {
      key: 'LogTime',
      header: '时间',
      render: ({ LogTime }: Ilog) => formatDateTime(LogTime),
    },
    {
      key: 'LogId',
      header: '日志id',
    },
    {
      key: 'NodeId',
      header: '节点IP和端口',
    },
    {
      key: 'Type',
      header: '报错类型',
    },
    {
      key: 'Actions',
      header: '操作',
      // eslint-disable-next-line react/display-name
      render: (record: Ilog, _Id, Index) => (
        <>
          <Button
            type="link"
            onClick={() => {
              downLoadLog(record);
            }}
          >
            下载
          </Button>
          {record.reporting ? (
            <Icon className="content-ml-1n" type="loading" />
          ) : (
            <Button
              type="link"
              className="tea-ml-2n"
              onClick={() => {
                reportLog(record, Index);
              }}
            >
              上报
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Body>
        <Content>
          <Content.Header title="报错日志监控" />
          <Content.Body full>
            <Justify
              left={
                <>
                  <Text theme="label">区块链</Text>
                  <ChainSelect onChange={onChangeChain} value={chainId} ref={chainSelectRef} />
                </>
              }
              right={
                <>
                  <Icon className="content-mr-2n tea-icon-m-size" type="refresh-blue" onClick={refreshList} />
                  <Switch value={isAutoReport} onChange={autoReport}>
                    自动上报
                  </Switch>
                </>
              }
            />
            <Card bordered className="tea-mt-5n">
              <Table
                recordKey="Id"
                records={logs}
                columns={defaultColumns}
                addons={[
                  pageable({
                    recordCount: totalCount,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageIndex: pageQuery.pageIndex,
                    pageSize: pageQuery.pageSize,
                    onPagingChange: (query) =>
                      setPageQuery({ pageIndex: query.pageIndex ?? 1, pageSize: query.pageSize ?? 10 }),
                  }),
                ]}
              />
            </Card>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
  );
}
