/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { PageQuery, ValueOf } from 'src/common/interface';
import { ChainModes } from '../chains/interface';

export enum CERTTYPE {
  ORGCERT = 0,
  NODECERT,
  USERCERT,
}

export enum CERTUSE {
  SIGNCERT = 0,
  SIGNKEY,
  TLSCERT,
  TLSKEY,
}

export type MapValueAsType<T> = T[keyof T];

export interface IFetchCertListParam extends PageQuery {
  Type: 0 | 1 | 2;
  OrgName?: string;
  NodeName?: string;
  UserName?: string;
  Addr?: string;
  ChainMode: string; // 链证书模式 permissionedWithCert 证书模式 public 公钥模式
}

/**
 * 密码算法， 0: 国密 1：非国密
 */
export const ChainAlgorithm = {
  CN: 0,
  NOT_CN: 1,
} as const;

export interface ICertItem {
  Id: number;
  UserName: string;
  OrgName: string;
  NodeName: string;
  CertUse: ValueOf<typeof CERTUSE>; // 0: 签名证书 1: 签名私钥 2: tls证书 3: tls私钥
  // public 账户类型 1 用户 2 节点
  CertType: number; // 0: 根证书 1: ca证书 2: 用户admin证书 3: 用户client证书 4: 共识节点证书 5: 同步节点证书
  CreateTime: number;
  Algorithm: MapValueAsType<typeof ChainAlgorithm>;
  RemarkName: string; // 账户地址
  Addr: string; //	账户地址
}

export interface IGenerateCertParam extends Pick<ICertItem, 'Algorithm'> {
  OrgId?: string;
  OrgName?: string;
  NodeName?: string;
  CertType: number; // 证书： 0 | 1 | 2;  公钥：1：节点证书； 2：用户证书；
  NodeRole?: 0 | 1;
  UserName?: string;
  UserRole?: 0 | 1 | 2;
  ChainMode?: string; // 链证书模式 permissionedWithCert 证书模式 public 公钥模式
  RemarkName?: string;
}

export interface IDownloadCertParam {
  CertId: number;
  CertUse: ValueOf<typeof CERTUSE>;
}

export interface IImportCertParam extends Pick<ICertItem, 'Algorithm'> {
  Type: number; // 证书： 0 | 1 | 2;  pk证书：导入证书类型，2：用户账户, 1：节点账户
  OrgId?: string;
  OrgName?: string;
  UserName?: string;
  NodeName?: string;
  CaCert?: string;
  CaKey?: string;
  SignCert?: string;
  SignKey?: string;
  TlsCert?: string;
  TlsKey?: string;
  RemarkName?: string;
  ChainMode: string; // 链证书模式 permissionedWithCert 证书模式 public 公钥模式
  PublicKey?: string;
  PrivateKey?: string;
}

export interface IDetailCertParam {
  CertId: number;
  // CertUse: ValueOf<typeof CERTUSE>;
}

export interface IDetailCertResponse {
  CertDetail: string;
  PrivateKey: string; //	私钥
  PublicKey: string; //	公钥
  SignCertDetail?: string;
  SignKeyDetail?: string;
  TlsCertDetail?: string;
  TlsKeyDetail?: string;
}

export interface Org {
  Id: number;
  OrgName: string;
  OrgId: string;
  NodeNum: number;
  CreateTime: number;
}

export type CertOrg = Pick<Org, 'OrgName' | 'OrgId'> & Pick<ICertItem, 'Algorithm'>;

export interface OrgListRequest {
  ChainId: string;
}

export interface CertNodeRequest {
  ChainId?: string;
  OrgId?: string;
  NodeRole?: 0 | 1;
  Algorithm?: number;
  ChainMode?: ChainModes;
}

export type CertNode = {
  NodeName: string;
};
