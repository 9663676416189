/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'tea-component';
import { Controller, useForm } from 'react-hook-form';
import formUtils, { Validator } from '../../utils/form-utils';
import { UserLogin, UserResponse } from '../../common/interface';
import { useLogin } from '../../common/hooks';
import { fetchCaptcha } from '../../common/apis/accounts';

export default function LoginPage({ onSuccess }: { onSuccess: (user: UserResponse) => void }) {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValidating, isSubmitted, isValid },
  } = useForm({ mode: 'onChange' });
  const { run: doLogin } = useLogin();
  const doLoginClick = useCallback(async () => {
    doLogin(getValues() as UserLogin)
      .then((res) => {
        onSuccess(res);
      })
      .catch(() => {
        handleCaptchaClick();
      });
  }, []);

  const [captcha, setCaptcha] = useState<string>();

  const handleCaptchaClick = useCallback(() => {
    fetchCaptcha().then((res) => {
      setCaptcha(res.data.Response.Data.Content);
    });
  }, []);

  useEffect(() => {
    handleCaptchaClick();
  }, []);

  return (
    <div className={'_cm-login'}>
      <div className={'_cm-login-nav'}>
        <img src="/static/icon/logo.svg" alt="" />
      </div>
      <div className={'_cm-login-con'}>
        <form className={'_cm-login-form'} onSubmit={handleSubmit(doLoginClick)}>
          <div className={'_cm-login-form__tit'}>长安链管理平台</div>
          <div className={'_cm-login-form__enter'}>
            <Form layout="vertical">
              <Controller
                control={control}
                rules={{
                  required: '请输入用户名',
                }}
                name="UserName"
                render={({ field, fieldState }) => (
                  <Form.Item
                    label="用户名"
                    required
                    status={formUtils.getStatus({
                      fieldState,
                      isValidating,
                      isSubmitted,
                    })}
                    message={fieldState.error?.message}
                  >
                    <Input autoComplete="off" placeholder="请输登陆用户名" {...field} size={'full'} />
                  </Form.Item>
                )}
              />
              <Controller
                control={control}
                rules={{
                  required: '请输入登陆密码',
                }}
                name="Password"
                render={({ field, fieldState }) => (
                  <Form.Item
                    status={formUtils.getStatus({
                      fieldState,
                      isValidating,
                      isSubmitted,
                    })}
                    required
                    label="密码"
                    message={fieldState.error?.message}
                  >
                    <Input.Password
                      placeholder="请输入登陆密码"
                      {...field}
                      autoComplete="off"
                      size={'full'}
                      rules={false}
                    />
                  </Form.Item>
                )}
              />

              <Controller
                control={control}
                name="Captcha"
                rules={{
                  validate: Validator.validateCaptcha,
                }}
                render={({ field, fieldState }) => (
                  <Form.Item
                    required
                    status={formUtils.getStatus({
                      fieldState,
                      isValidating,
                      isSubmitted,
                    })}
                    label="验证码"
                    message={fieldState.error?.message}
                  >
                    <Input
                      placeholder="请输入验证码"
                      {...field}
                      autoComplete="off"
                      className="_cm_login-vercode-input"
                    />
                    <img src={captcha} onClick={handleCaptchaClick} className={'_cm-login-vercode'} />
                  </Form.Item>
                )}
              />
              <Button type="primary" disabled={!isValid} className={'form-btn'}>
                立即登录
              </Button>
            </Form>
          </div>
          <div className={'_cm-login-form__footer'}>忘记密码请联系管理员重置密码</div>
        </form>
      </div>
    </div>
  );
}
