/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Justify, PagingQuery, SearchBox, Table, TableColumn } from 'tea-component';
import { formatDate } from 'src/utils/date';
import { ICertItem, IDetailCertParam } from 'src/common/apis/certificates/interface';
import { GenerateOrgCertModal } from './modals/generate-org-cert-modal';
import { ImportOrgCertModal } from './modals/import-org-cert-modal';
import CertMultipleDetailModal from '../modals/cert-multiple-detail-modal';
import { useFetchCertList, useFetchRemoveCertDetail } from 'src/common/apis/certificates/hooks';
import { PAGE_SIZE_OPTIONS } from 'src/utils/common';
import { renderChainAlgorithm } from '../node-cert';

const { pageable, autotip } = Table.addons;

export function OrganizationCert() {
  const [generateModalVisible, setGenerateModalVisible] = useState<boolean>(false);
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [targetCert, setTargetCert] = useState<ICertItem | null>(null);
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueInput, setSearchValueInput] = useState<string>('');
  const { list, totalCount, fetch } = useFetchCertList();
  const { fetchRemoveCert } = useFetchRemoveCertDetail();

  const defaultColumns: TableColumn<ICertItem>[] = [
    {
      key: 'OrgName',
      header: '组织名称',
    },
    {
      key: 'Algorithm',
      header: '密码算法',
      render: renderChainAlgorithm,
    },
    {
      key: 'CreateTime',
      header: '创建时间',
      render: ({ CreateTime }) => formatDate(CreateTime),
    },
    {
      key: 'Action',
      header: '操作',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setTargetCert(record);
              setDetailModalVisible(true);
            }}
          >
            查看
          </Button>
          <Button
            type="link"
            onClick={() => {
              fetchDeleteCert({ CertId: record.Id });
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ];

  const fetchList = useCallback(() => {
    fetch({
      Type: 0,
      OrgName: searchValue ?? '',
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
      ChainMode: 'permissionedWithCert',
    });
  }, [fetch, searchValue, pageQuery]);

  const fetchDeleteCert = useCallback(
    (param: IDetailCertParam) => {
      fetchRemoveCert(param).then(() => {
        fetchList();
      });
    },
    [fetchList],
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>
      <GenerateOrgCertModal
        visible={generateModalVisible}
        close={() => setGenerateModalVisible(false)}
        successCallback={() => {
          fetchList();
          setGenerateModalVisible(false);
        }}
      />
      <ImportOrgCertModal
        visible={importModalVisible}
        close={() => setImportModalVisible(false)}
        successCallback={() => {
          fetchList();
          setImportModalVisible(false);
        }}
      />
      <CertMultipleDetailModal
        zipName={targetCert?.OrgName}
        visible={detailModalVisible}
        CertType={0}
        CertId={targetCert?.Id}
        close={() => setDetailModalVisible(false)}
      />
      <Justify
        left={
          <>
            <Button type="primary" onClick={() => setGenerateModalVisible(true)}>
              申请组织证书
            </Button>
            <Button type="primary" onClick={() => setImportModalVisible(true)}>
              导入组织证书
            </Button>
          </>
        }
        right={
          <SearchBox
            placeholder="请输入关键词"
            size="l"
            value={searchValueInput}
            onChange={(value) => setSearchValueInput(value)}
            onSearch={(value) => {
              setSearchValue(value ?? '');
              setPageQuery({
                ...pageQuery,
                pageIndex: 1,
              });
            }}
            onClear={() => {
              setSearchValue('');
              setPageQuery({
                ...pageQuery,
                pageIndex: 1,
              });
            }}
          />
        }
      />
      <Card className="tea-mt-5n">
        <Table
          records={list}
          recordKey={(record) => `${record.Id}--${record.CertUse}`}
          columns={defaultColumns}
          addons={[
            pageable({
              recordCount: totalCount,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              pageIndex: pageQuery.pageIndex,
              pageSize: pageQuery.pageSize,
              onPagingChange: ({ pageIndex, pageSize }) =>
                setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
            }),
            autotip({
              emptyText: '暂无数据',
              isFound: !!searchValue.length,
              onClear: () => {
                setSearchValue('');
                setSearchValueInput('');
              },
            }),
          ]}
        />
      </Card>
    </>
  );
}
