/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect, useRef } from 'react';
import { Button, Form, Input, Modal, Select } from 'tea-component';
import { Controller, useForm } from 'react-hook-form';
import formUtils, { Validator } from 'src/utils/form-utils';
import { useFetchImportCert } from 'src/common/apis/certificates/hooks';
import { useFetchCertOrgList } from 'src/common/apis/chains/hooks';
import { UploadFile } from '../../../../common/components/upload-file';
import { IImportCertParam } from '../../../../common/apis/certificates/interface';
import {
  renderChainAlgorithmFormItem,
  useUpdateAlgorithmByOrg,
} from '../../organization-cert/modals/import-org-cert-modal';

const { getStatus } = formUtils;

interface IImportCertModalParam {
  visible: boolean;
  close: () => void;
  successCallback?: () => void;
}

const initialData = {
  Type: 1,
  OrgId: '',
  OrgName: '',
  NodeName: '',
};

export function ImportNodeCertModal({ visible, close, successCallback }: IImportCertModalParam) {
  const { fetch } = useFetchImportCert();
  const { orgList, run: fetchOrgList } = useFetchCertOrgList();
  const signCertRef = useRef<any>(null);
  const signKeyRef = useRef<any>(null);
  const tlsCertRef = useRef<any>(null);
  const tlsKeyRef = useRef<any>(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitted, isSubmitting, isValidating, isValid },
  } = useForm({
    mode: 'onChange',
  });
  useUpdateAlgorithmByOrg(orgList, control, setValue);

  const submit = async () => {
    const result = {
      ...getValues(),
      Type: initialData.Type,
      ChainMode: 'permissionedWithCert',
    };
    await fetch(result as IImportCertParam);
    successCallback?.();
  };

  useEffect(() => {
    if (visible) {
      reset();
      fetchOrgList();
    }
  }, [visible]);
  return (
    <Modal caption="导入节点证书" visible={visible} onClose={close}>
      <Modal.Body>
        <Form>
          <Controller
            name="OrgId"
            control={control}
            defaultValue={initialData.OrgId}
            rules={{
              validate: (OrgId) => Validator.validateOrgId(OrgId, 'select'),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="选择组织"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.OrgId?.message}
              >
                <Select
                  placeholder="请选择组织"
                  type="simulate"
                  appearance="button"
                  size="full"
                  options={orgList.map((item) => ({
                    text: item.OrgName,
                    value: item.OrgId,
                  }))}
                  {...field}
                  onChange={(value) => {
                    setValue('OrgId', value);
                    setValue('OrgName', orgList.find((item) => item.OrgId === value)?.OrgName);
                  }}
                />
              </Form.Item>
            )}
          />
          <Controller
            name="NodeName"
            control={control}
            defaultValue={initialData.NodeName}
            rules={{
              validate: (NodeName) => Validator.validateNodeName(NodeName),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="节点名称"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.NodeName?.message}
              >
                <Input placeholder="请输入节点名称" size="full" {...field} />
              </Form.Item>
            )}
          />
          {renderChainAlgorithmFormItem(control, isSubmitted, isValidating, true)}
          <Controller
            name="SignCert"
            control={control}
            rules={{
              validate: (value) => {
                if (!value && signCertRef.current?.status === null) {
                  return '请上传节点签名证书';
                }
                if (!value && signCertRef.current?.status === 'error') {
                  return '上传节点签名证书格式不正确';
                }
                return undefined;
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="节点签名证书"
                required
                status={signCertRef.current?.status}
                message={fieldState.error?.message}
              >
                <UploadFile
                  accept="application/x-x509-ca-cert,.cer,.crt"
                  {...field}
                  ref={signCertRef}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
          <Controller
            name="SignKey"
            control={control}
            rules={{
              validate: (value) => {
                if (!value && signKeyRef.current?.status === null) {
                  return '请上传节点签名私钥';
                }
                if (!value && signKeyRef.current?.status === 'error') {
                  return '上传节点签名私钥格式不正确';
                }
                return undefined;
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="节点签名私钥"
                required
                status={signKeyRef.current?.status}
                message={fieldState.error?.message}
              >
                <UploadFile
                  accept=".key"
                  {...field}
                  ref={signKeyRef}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
          <Controller
            name="TlsCert"
            control={control}
            rules={{
              validate: (value) => {
                if (!value && tlsCertRef.current?.status === null) {
                  return '请上传合约文件';
                }
                if (!value && tlsCertRef.current?.status === 'error') {
                  return '上传合约文件格式不正确';
                }
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="节点TLS证书"
                required
                status={tlsCertRef.current?.status}
                message={fieldState.error?.message}
              >
                <UploadFile
                  accept="application/x-x509-ca-cert,.cer,.crt"
                  {...field}
                  ref={tlsCertRef}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
          <Controller
            name="TlsKey"
            control={control}
            rules={{
              validate: (value) => {
                if (!value && tlsKeyRef.current?.status === null) {
                  return '请上传节点TLS私钥';
                }
                if (!value && tlsKeyRef.current?.status === 'error') {
                  return '上传节点TLS私钥格式不正确';
                }
                return undefined;
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="节点TLS私钥"
                required
                status={tlsKeyRef.current?.status}
                message={fieldState.error?.message}
              >
                <UploadFile
                  accept=".key"
                  {...field}
                  ref={tlsKeyRef}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={isSubmitting || !isValid} onClick={handleSubmit(submit)}>
          确定
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}
