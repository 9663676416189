/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */

import { Error, UserLogin, UserResponse } from './interface';
import { LocalSetting } from './local-setting';
import { useState } from 'react';
/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { fetchLogin } from '../common/apis/accounts';
import { useDispatchMessage } from '../utils/hooks';

export function useLogin() {
  const [user, setUser] = useState<UserResponse | null>(null);
  const { errorNotification } = useDispatchMessage();

  const login = async (params: UserLogin) => {
    try {
      const user: UserResponse = (await fetchLogin(params)).data.Response.Data;
      LocalSetting.saveToken(user.Token);
      LocalSetting.saveUser(user);
      setUser(user);
      return user;
    } catch (e) {
      errorNotification({
        title: '登录失败',
        description: (e as Error).Message,
      });
      throw e;
    }
  };

  return {
    run: login,
    data: user,
  };
}
