/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect } from 'react';
import { Button, Form, Input, Modal, Select } from 'tea-component';
import { Controller, useForm } from 'react-hook-form';
import formUtils, { Validator } from 'src/utils/form-utils';
import { useFetchCertOrgList } from 'src/common/apis/chains/hooks';
import { useFetchGenerateCert } from 'src/common/apis/certificates/hooks';
import {
  renderChainAlgorithmFormItem,
  useUpdateAlgorithmByOrg,
} from '../../organization-cert/modals/import-org-cert-modal';
import { ChainAlgorithm, ICertItem } from '../../../../common/apis/certificates/interface';

const { getStatus } = formUtils;

interface IGenerateCertModalParam {
  visible: boolean;
  close: () => void;
  successCallback?: () => void;
}

interface IInitialData {
  NodeName: string;
  OrgName: string;
  OrgId: string;
  NodeRole: '0' | '1';
  Algorithm: string;
}

const initialData: IInitialData = {
  NodeName: '',
  OrgName: '',
  OrgId: '',
  NodeRole: '0',
  Algorithm: ChainAlgorithm.CN.toString(),
};

export function NodeBottomTip({ className }: { className?: string }) {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: `注意为了后续生成链的配置文件以及订阅链时能简化操作，此处需要预填写节点最终会被部署的ip和端口， 请确保端口不要被占用。<br>如果是容器启动的管理台，不能使用127.0.0.1作为ip地址。`,
      }}
    />
  );
}

export function GenerateNodeCertModal({ visible, close, successCallback }: IGenerateCertModalParam) {
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValidating, isSubmitting, isSubmitted, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const { orgList, run: fetchOrgList } = useFetchCertOrgList();
  const { fetch: generateCert } = useFetchGenerateCert();

  useUpdateAlgorithmByOrg(orgList, control, setValue);

  const submit = async (values: IInitialData) => {
    await generateCert({
      CertType: 1,
      ...values,
      NodeRole: values.NodeRole === '0' ? 0 : 1,
      Algorithm: +values.Algorithm as ICertItem['Algorithm'],
    });
    successCallback?.();
  };

  useEffect(() => {
    if (visible) {
      reset();
      fetchOrgList();
    }
  }, [visible]);
  return (
    <Modal caption="申请节点证书" visible={visible} onClose={close}>
      <Modal.Body>
        <Form>
          <Controller
            name="NodeName"
            control={control}
            defaultValue={initialData.NodeName}
            rules={{
              validate: (NodeName) => Validator.validateNodeName(NodeName),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="节点名称"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.NodeName?.message}
              >
                <Input placeholder="请输入节点名称" size="full" {...field} />
              </Form.Item>
            )}
          />
          <Controller
            name="OrgId"
            control={control}
            defaultValue={initialData.OrgId}
            rules={{
              validate: (OrgId) => Validator.validateOrgId(OrgId, 'select'),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="选择组织"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.OrgId?.message}
              >
                <Select
                  placeholder="请选择组织"
                  type="simulate"
                  appearance="button"
                  size="full"
                  options={orgList.map((item) => ({
                    text: item.OrgName,
                    value: item.OrgId,
                  }))}
                  {...field}
                  onChange={(value) => {
                    setValue('OrgId', value);
                    setValue('OrgName', orgList.find((item) => item.OrgId === value)?.OrgName);
                  }}
                />
              </Form.Item>
            )}
          />
          {renderChainAlgorithmFormItem(control, isSubmitted, isValidating, true)}
          <Controller
            name="NodeRole"
            control={control}
            defaultValue={initialData.NodeRole}
            rules={{
              validate: (NodeRole) => {
                if (NodeRole === null || NodeRole === undefined) {
                  return '请选择节点角色';
                }
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="节点角色"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.NodeRole?.message}
              >
                <Select
                  placeholder="请选择节点角色"
                  type="simulate"
                  appearance="button"
                  options={[
                    {
                      value: '0',
                      text: '共识节点',
                    },
                    {
                      value: '1',
                      text: '同步节点',
                    },
                  ]}
                  size="full"
                  {...field}
                />
              </Form.Item>
            )}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={isSubmitting || !isValid} onClick={handleSubmit(submit)}>
          确定
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}
