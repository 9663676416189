/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { UserResponse } from './interface';

export class LocalSetting {
  static saveToken(token: string): void {
    localStorage.setItem('LOGIN_TOKEN', token);
  }

  static getToken(): string | null {
    return localStorage.getItem('LOGIN_TOKEN') as string;
  }

  static saveUser(user: UserResponse): void {
    localStorage.setItem('LOGIN_USER', JSON.stringify(user));
  }

  static getUser(): UserResponse | null {
    try {
      return JSON.parse(localStorage.getItem('LOGIN_USER') as string) as UserResponse;
    } catch {
      return null;
    }
  }

  static logout(): void {
    localStorage.removeItem('LOGIN_TOKEN');
    localStorage.removeItem('LOGIN_USER');
  }
}
