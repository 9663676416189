/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { LinkNodeList } from 'src/common/apis/chains/interface';

interface IDataItem {
  id: number;
  name: string;
  symbolSize: number;
  value: string;
  category: number;
}

interface ILinkItem {
  source: number;
  target: number;
}

const createLinkAndData = (NodeList: LinkNodeList[]) => {
  const data: IDataItem[] = [];
  const links: ILinkItem[] = [];
  if (!NodeList)
    return {
      data,
      links,
    };
  NodeList.forEach((node, idx) => {
    data.push({
      id: idx + 1,
      name: node.LinkNodeName,
      symbolSize: node.LinkNodeType === 0 ? 100 : 80,
      value: node.LinkNodeName,
      category: node.LinkNodeType === 0 ? 1 : 2,
    });

    links.push({
      source: 0,
      target: idx + 1,
    });
  });
  return {
    data,
    links,
  };
};

export function GenerateGraphOption({ NodeName, NodeList }: { NodeName: string; NodeList: LinkNodeList[] }) {
  const defaultData = [
    {
      id: 0,
      name: NodeName,
      symbolSize: 120,
      value: NodeName,
      category: 0,
    },
  ];
  const { data, links } = createLinkAndData(NodeList);
  const graphOption = {
    legend: [
      {
        data: ['当前节点', '共识节点', '同步节点'],
      },
    ],
    series: [
      {
        type: 'graph',
        layout: 'force',
        roam: true,
        categories: [{ name: '当前节点' }, { name: '共识节点' }, { name: '同步节点' }],
        force: {
          repulsion: 4000,
        },
        links,
        data: [...defaultData, ...data],
      },
    ],
  };

  return graphOption;
}
