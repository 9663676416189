/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Justify, Layout, PagingQuery, Table, TableColumn, Text } from 'tea-component';
import { useFetchUserList } from 'src/common/apis/accounts/hooks';
import { PAGE_SIZE_OPTIONS } from 'src/utils/common';

import { CreateAccountModal } from './modals/create-account-modal';
import { EnableAccountModal } from './modals/enable-account-modal';
import { DisableAccountModal } from './modals/disable-account-modal';
import { ResetPasswordModal } from './modals/reset-password-modal';
import { LocalSetting } from 'src/common/local-setting';
import { formatDate } from 'src/utils/date';
import { IUserItem } from 'src/common/apis/accounts/interface';

const { Body, Content } = Layout;
const { addons } = Table;
const { pageable } = addons;

interface IUserStatus {
  [key: number]: {
    label: string;
    theme: 'success' | 'label' | 'strong' | 'text' | 'weak' | 'primary' | 'warning' | 'danger' | 'cost' | undefined;
  };
}

const USER_STATUS_MAP: IUserStatus = {
  0: {
    label: '正常',
    theme: 'success',
  },
  1: {
    label: '已禁用',
    theme: 'danger',
  },
};

export default function AccountsPage() {
  const [targetUser, setTargetUser] = useState<IUserItem | null>(null);
  const [createAccountModalVisible, setCreateAccountModalVisible] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [enableAccountModalVisible, setEnableAccountModalVisible] = useState(false);
  const [disableAccountModalVisible, setDisableAccountModalVisible] = useState(false);
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const { list, totalCount, fetch } = useFetchUserList();
  const { UserId } = LocalSetting.getUser()!;
  const defaultColumns: TableColumn<IUserItem>[] = [
    {
      key: 'Name',
      header: '姓名',
    },
    {
      key: 'UserName',
      header: '用户名',
    },
    {
      key: 'CreateTime',
      header: '创建时间',
      render: ({ CreateTime }: IUserItem) => formatDate(CreateTime),
    },
    {
      key: 'Status',
      header: '状态',
      render: ({ Status }: IUserItem) => (
        <Text theme={USER_STATUS_MAP[Status].theme}>{USER_STATUS_MAP[Status].label}</Text>
      ),
    },
    {
      key: 'Actions',
      header: '操作',
      render: (record: IUserItem) => {
        const { Status } = record;
        return Status === 0 ? (
          <>
            <Button
              type="link"
              onClick={() => {
                setTargetUser(record);
                setDisableAccountModalVisible(true);
              }}
            >
              禁用
            </Button>
            <Button
              type="link"
              className="tea-ml-2n"
              onClick={() => {
                setTargetUser(record);
                setResetPasswordModalVisible(true);
              }}
            >
              重置密码
            </Button>
          </>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setTargetUser(record);
              setEnableAccountModalVisible(true);
            }}
          >
            启用
          </Button>
        );
      },
    },
  ];

  const fetchList = useCallback(() => {
    fetch({
      UserId,
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
    });
  }, [fetch, pageQuery]);
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Layout>
      <Body>
        <Content>
          <Content.Header title="平台账号管理" />
          <Content.Body full>
            <CreateAccountModal
              visible={createAccountModalVisible}
              close={() => setCreateAccountModalVisible(false)}
              successCallback={() => {
                setCreateAccountModalVisible(false);
                fetchList();
              }}
            />
            <ResetPasswordModal
              visible={resetPasswordModalVisible}
              userId={targetUser?.Id}
              close={() => setResetPasswordModalVisible(false)}
              successCallback={() => {
                setResetPasswordModalVisible(false);
                fetchList();
              }}
            />
            <EnableAccountModal
              visible={enableAccountModalVisible}
              userId={targetUser?.Id}
              close={() => setEnableAccountModalVisible(false)}
              successCallback={() => {
                setEnableAccountModalVisible(false);
                fetchList();
              }}
            />
            <DisableAccountModal
              visible={disableAccountModalVisible}
              userId={targetUser?.Id}
              close={() => setDisableAccountModalVisible(false)}
              successCallback={() => {
                setDisableAccountModalVisible(false);
                fetchList();
              }}
            />
            <Justify
              left={
                <Button type="primary" onClick={() => setCreateAccountModalVisible(true)}>
                  新增账号
                </Button>
              }
            />
            <Card bordered className="tea-mt-5n">
              <Table
                recordKey="Id"
                records={list}
                columns={defaultColumns}
                addons={[
                  pageable({
                    recordCount: totalCount,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageIndex: pageQuery.pageIndex,
                    pageSize: pageQuery.pageSize,
                    onPagingChange: (query) =>
                      setPageQuery({ pageIndex: query.pageIndex ?? 1, pageSize: query.pageSize ?? 10 }),
                  }),
                ]}
              />
            </Card>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
  );
}
