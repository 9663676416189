/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { useCallback, useState } from 'react';
import {
  AuthOrg,
  AuthOrgRequest,
  AuthRole,
  CertUser,
  Chain,
  ChainAuth,
  ChainAuthRequest,
  ChainCreateRequest,
  ChainDetail,
  ChainGeneral,
  ChainMode,
  ChainReConfigRequest,
  ChainSubscribeConfig,
  ChainSubscribeRequest,
  ContractForInvoke,
  ContractFreezeRequest,
  ContractInstallRequest,
  ContractManage,
  ContractManageListRequest,
  ContractRuntimeType,
  ContractUpgradeRequest,
  GetCertUserListParam,
  IChainBrowserBlockDetail,
  IChainBrowserBlockDetailRequest,
  IChainBrowserBlockItem,
  IChainBrowserContractDetail,
  IChainBrowserContractDetailRequest,
  IChainBrowserContractItem,
  IChainBrowserTableRequest,
  IChainBrowserTxDetail,
  IChainBrowserTxDetailRequest,
  IChainBrowserTxItem,
  IChainContractDetail,
  IChainNodeDetail,
  IChainNodeDetailRequest,
  IChainNodeItem,
  IChainNodeListRequest,
  IChainOrgItem,
  IChainOrgListRequest,
  IHomePageSearch,
  IHomePageSearchRequest,
  InvokeRecord,
  InvokeRecordListRequest,
  InvokeRecordRequest,
  VoteDetail,
  VoteManage,
  VoteRequest,
  VotManageListRequest,
  GetAdminResData,
  ContractDeleteRequest,
} from './interface';
import {
  fetchAuthOrgList,
  fetchAuthRoleList,
  fetchCertNodeList,
  fetchCertOrgList,
  fetchCertUserList,
  fetchChainAuth,
  fetchChainAuthList,
  fetchChainBrowerContractList,
  fetchChainBrowserBlockDetail,
  fetchChainBrowserBlockList,
  fetchChainBrowserContractDetail,
  fetchChainBrowserTxDetail,
  fetchChainBrowserTxList,
  fetchChainConsensusList,
  fetchChainContractDetail,
  fetchChainCreate,
  fetchChainDetail,
  fetchChainDownload,
  fetchChainGeneral,
  fetchChainList,
  fetchChainModes,
  fetchChainNodeDetail,
  fetchChainNodeList,
  fetchChainOrgList,
  fetchChainReConfig,
  fetchChainSubscribe,
  fetchChainSubscribeConfig,
  fetchChainVote,
  fetchChainVoteDetail,
  fetchChainVoteManageList,
  fetchContractFreeze,
  fetchContractInstall,
  fetchContractManageList,
  fetchContractRevoke,
  fetchContractRuntimeTypeList,
  fetchContractUnFreeze,
  fetchContractUpgrade,
  fetchHomePageSearch,
  fetchInvokeChain,
  fetchInvokeChainContractList,
  fetchInvokeRecordList,
  fetchReInvokeChain,
  ModifyChainContractDetail,
  GetAdminList,
  fetchCMCAndSDKDownload,
  fetchContractDelete,
} from './index';
import { CertNode, CertOrg } from '../certificates/interface';
import { useDispatchMessage } from 'src/utils/hooks';
import { ContractOperateType } from '../../../routes/chains/chain-contracts';
import { saveAs, saveBlobAs } from '../../../utils/common';
import { ChainConsensus, Error, ErrorCode } from 'src/common/interface';
import { AuthRoleMap } from '../../../routes/chains/chain-auth';
import { DOWNLOAD_FILE_URL } from 'src/utils/request';

type ApiData<T> = { list: T[]; totalCount: number };

/**
 * 获取证书组织列表
 * @param chainId
 */
export function useFetchCertOrgList() {
  const [orgList, setOrgList] = useState<CertOrg[]>([]);
  const run = useCallback(
    (...params: Parameters<typeof fetchCertOrgList>) =>
      fetchCertOrgList(...params).then((res) => {
        setOrgList(res.data.Response.GroupList);
      }),
    [],
  );
  return {
    orgList,
    run,
  };
}

/**
 * 获取证书节点列表
 * @param chainId
 */
export function useFetchCertNodeList() {
  const [nodeList, setNodeList] = useState<CertNode[]>([]);
  const run = useCallback(
    (params: Parameters<typeof fetchCertNodeList>[0]) =>
      fetchCertNodeList(params).then((res) => {
        setNodeList(res.data.Response.GroupList);
      }),
    [],
  );

  return {
    nodeList: nodeList.length ? nodeList : [],
    run,
  };
}

/**
 * 获取证书用户列表
 */
export function useFetchCertUserList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<CertUser[]>([]);
  const run = useCallback((params?: GetCertUserListParam) => {
    try {
      fetchCertUserList(params).then((res) => {
        setList(res.data.Response.GroupList);
      });
    } catch (err) {
      errorNotification({
        title: '获取链管理员列表列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
    list,
  };
}

/**
 * 区块链概览统计
 */
export function useFetchChainGeneral() {
  const [chain, setChain] = useState<ChainGeneral>();
  const run = useCallback(
    (id: string) =>
      fetchChainGeneral(id).then((res) => {
        setChain(res.data.Response.Data);
      }),
    [],
  );
  return {
    chain,
    run,
  };
}
/**
 * 下载链配置.链cmc/sdk
 */
export function useFetchCMCAndSDKDownload() {
  const fetchCMCAndSDKConfig = useCallback(async (chainId: string) => {
    const { data, headers } = await fetchCMCAndSDKDownload(chainId);
    saveBlobAs(headers, data);
  }, []);

  return {
    fetchCMCAndSDKConfig,
  };
}
export function useFetchChainDetail() {
  const [chain, setChain] = useState<ChainDetail>();
  const run = useCallback(
    (chainId: string) =>
      fetchChainDetail(chainId).then((res) => {
        setChain(res.data.Response.Data);
      }),
    [],
  );
  return {
    chain,
    run,
  };
}

export function useFetchChainSubscribeConfig() {
  const [data, setData] = useState<ChainSubscribeConfig>();
  const run = useCallback(
    (chainId: string) =>
      fetchChainSubscribeConfig({ ChainId: chainId }).then((res) => {
        setData(res.data.Response.Data);
      }),
    [],
  );
  return {
    data,
    run,
  };
}

export function useFetchChainOrgList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<IChainOrgItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const fetch = useCallback(async (params: IChainOrgListRequest) => {
    try {
      const result = await fetchChainOrgList(params);
      setList(result.data.Response.GroupList);
      setTotalCount(result.data.Response.TotalCount);
    } catch (err) {
      errorNotification({
        title: '获取组织信息列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    list,
    totalCount,
    fetch,
  };
}

/**
 * 获取链权限参与组织列表
 */
export function useFetchAuthOrgList() {
  const [orgs, setOrgs] = useState<AuthOrg[]>([]);
  const run = useCallback(async (chainId: string, type?: number, AuthName?: string) => {
    let orgs: AuthOrg[];
    if (type !== undefined) {
      orgs = (await fetchAuthOrgList({ ChainId: chainId, Type: type, AuthName: AuthName || '' })).data.Response
        .GroupList;
    } else {
      orgs = (await fetchCertOrgList(chainId)).data.Response.GroupList.map((item) => ({
        ...item,
        Selected: 0,
      }));
    }
    setOrgs(orgs);
  }, []);
  return {
    orgs,
    run,
  };
}

/**
 * 获取合约管理列表
 */
export function useFetchContractManageList() {
  const [data, setData] = useState<ApiData<ContractManage>>({ list: [], totalCount: 0 });
  const run = useCallback(
    (params: ContractManageListRequest) =>
      fetchContractManageList(params).then((res) => {
        setData({
          list: res.data.Response.GroupList,
          totalCount: res.data.Response.TotalCount,
        });
      }),
    [],
  );
  return {
    data,
    run,
  };
}

/**
 * 获取合约列表-上链专用
 */
export function useFetchInvokeChainContractList() {
  const [data, setData] = useState<ApiData<ContractForInvoke>>({ list: [], totalCount: 0 });
  const run = useCallback(
    (chainId: string) =>
      fetchInvokeChainContractList(chainId).then((res) => {
        setData({
          list: res.data.Response.GroupList,
          totalCount: res.data.Response.TotalCount,
        });
      }),
    [],
  );
  return {
    data,
    run,
  };
}

/**
 * 上链
 */
export function useFetchInvokeChain() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const [loading, setLoading] = useState(false);
  const [queryResult, setQueryResult] = useState<{ ContractResult?: string }>({ ContractResult: '' });

  const run = useCallback((params: InvokeRecordRequest) => {
    try {
      setLoading(true);
      const finalParams = {
        ...params,
      };
      if (finalParams.Parameters?.length === 0) {
        delete finalParams.Parameters;
      }
      fetchInvokeChain(finalParams).then((res) => {
        console.log(res.data.Response.Data);
        if (params.MethodFunc === 'query') {
          setQueryResult(res.data.Response.Data);
        }
        successNotification({
          description: params.MethodFunc === 'query' ? '查询成功' : '上链成功',
        });
      });
    } catch (err) {
      errorNotification({
        title: '上链失败',
        description: (err as Error).Message,
      });
    }
    setLoading(false);
  }, []);

  return {
    run,
    loading,
    queryResult,
  };
}

/**
 * 重新上链
 */
export function useFetchReInvokeChain() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const run = useCallback(async (id: number) => {
    try {
      await fetchReInvokeChain({ InvokeRecordId: id });
      successNotification({
        description: '重新上链成功',
      });
    } catch (err) {
      errorNotification({
        title: '重新上链失败',
        description: (err as Error).Message,
      });
      throw err;
    }
  }, []);

  return {
    run,
  };
}

/**
 * 获取虚拟机类型列表
 */
export function useFetchContractRuntimeTypeList() {
  const [data, setData] = useState<ContractRuntimeType[]>();
  const run = useCallback(
    () =>
      fetchContractRuntimeTypeList().then((res) => {
        setData(res.data.Response.GroupList);
      }),
    [],
  );
  return {
    runtimeTypeList: data,
    run,
  };
}

/**
 * 获取上链列表
 */
export function useFetchInvokeRecordList() {
  const [data, setData] = useState<{ list: InvokeRecord[]; totalCount: number }>({ list: [], totalCount: 0 });
  const run = useCallback((params: InvokeRecordListRequest) => {
    setData({ list: [], totalCount: 0 });
    return fetchInvokeRecordList(params).then((res) => {
      setData({
        list: res.data.Response.GroupList,
        totalCount: res.data.Response.TotalCount,
      });
    });
  }, []);
  return {
    data,
    run,
  };
}

/**
 * 获取投票管理列表
 */
export function useFetchVoteManagementList() {
  const [data, setData] = useState<{ list: VoteManage[]; totalCount: number }>({ list: [], totalCount: 0 });
  const run = useCallback(
    (params: VotManageListRequest) =>
      fetchChainVoteManageList(params).then((res) => {
        setData({
          list: res.data.Response.GroupList,
          totalCount: res.data.Response.TotalCount,
        });
      }),
    [],
  );
  return {
    data,
    run,
  };
}

/**
 * 获取链权限参与角色列表
 */
export function useFetchAuthRoleList() {
  const [data, setData] = useState<AuthRole[]>([]);
  const run = useCallback(
    async (
      params: Omit<AuthOrgRequest, 'Type'> & {
        Type?: AuthOrgRequest['Type'];
        AuthName: string;
      },
    ) => {
      let roles: AuthRole[];
      if (params.Type !== undefined) {
        roles = (await fetchAuthRoleList(params as AuthOrgRequest)).data.Response.GroupList;
      } else {
        roles = Object.keys(AuthRoleMap).map((item) => ({
          Selected: 0,
          Role: item as any,
        }));
      }
      setData(roles);
    },
    [],
  );
  return {
    data,
    run,
  };
}

/**
 * 获取投票详情
 */
export function useFetchVoteDetail() {
  const [data, setData] = useState<VoteDetail | null>(null);
  const run = useCallback(
    (voteId: number) =>
      fetchChainVoteDetail({ VoteId: voteId }).then((res) => {
        setData(res.data.Response.Data);
      }),
    [],
  );
  return {
    data,
    run,
  };
}

/**
 * 获取投票详情
 */
export function useFetchVote(isPublic?: boolean) {
  const { errorNotification, successNotification } = useDispatchMessage();
  const [loading, setLoading] = useState(false);
  const run = useCallback(async (params: VoteRequest) => {
    setLoading(true);
    try {
      await fetchChainVote(params);
      successNotification({
        description: '投票成功',
      });
    } catch (err) {
      errorNotification({
        title:
          isPublic && (err as Error).Code === ErrorCode.AdminNotImport
            ? '请先将该管理员信息导入到本平台，再进行投票。'
            : '投票失败。',
        description: (err as Error).Message,
      });
    }
    setLoading(false);
  }, []);
  return {
    run,
    loading,
  };
}

/**
 * 获取区块列表
 */
export function useFetchBlockList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<IChainBrowserBlockItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetch = useCallback(async (params: IChainBrowserTableRequest) => {
    try {
      const result = await fetchChainBrowserBlockList(params);
      setList(result.data.Response.GroupList);
      setTotalCount(result.data.Response.TotalCount);
    } catch (err) {
      errorNotification({
        title: '获取区块列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    list,
    totalCount,
    fetch,
  };
}

/**
 * 获取区块详情
 */
export function useFetchBlockDetail() {
  const { errorNotification } = useDispatchMessage();
  const [detail, setDetail] = useState<IChainBrowserBlockDetail | null>(null);

  const fetch = useCallback(async (params: IChainBrowserBlockDetailRequest) => {
    try {
      const result = await fetchChainBrowserBlockDetail(params);
      setDetail(result.data.Response.Data);
    } catch (err) {
      errorNotification({
        title: '获取区块信息详情失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    detail,
    fetch,
  };
}

/**
 * 获取交易信息列表
 */
export function useFetchDealList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<IChainBrowserTxItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetch = useCallback(async (params: IChainBrowserTableRequest) => {
    try {
      const result = await fetchChainBrowserTxList(params);
      setList(result.data.Response.GroupList);
      setTotalCount(result.data.Response.TotalCount);
    } catch (err) {
      errorNotification({
        title: '获取交易信息列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    list,
    totalCount,
    fetch,
  };
}

/**
 * 获取交易信息详情
 */
export function useFetchDealDetail() {
  const { errorNotification } = useDispatchMessage();
  const [detail, setDetail] = useState<IChainBrowserTxDetail | null>(null);

  const fetch = useCallback(async (params: IChainBrowserTxDetailRequest) => {
    try {
      const result = await fetchChainBrowserTxDetail(params);
      setDetail(result.data.Response.Data);
    } catch (err) {
      errorNotification({
        title: '获取交易信息详情失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    detail,
    fetch,
  };
}

/**
 * 获取合约信息列表
 */
export function useFetchBrowserContractList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<IChainBrowserContractItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetch = useCallback(async (params: IChainBrowserTableRequest) => {
    try {
      const result = await fetchChainBrowerContractList(params);
      setList(result.data.Response.GroupList);
      setTotalCount(result.data.Response.TotalCount);
    } catch (err) {
      errorNotification({
        title: '获取合约信息列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    list,
    totalCount,
    fetch,
  };
}

/**
 * 获取合约详情
 */
export function useFetchBrowserContractDetail() {
  const { errorNotification } = useDispatchMessage();
  const [detail, setDetail] = useState<IChainBrowserContractDetail | null>(null);

  const fetch = useCallback(async (params: IChainBrowserContractDetailRequest) => {
    try {
      const result = await fetchChainBrowserContractDetail(params);
      setDetail(result.data.Response.Data);
    } catch (err) {
      errorNotification({
        title: '获取交易信息详情失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    detail,
    fetch,
  };
}

/**
 * 获取合约详情
 */
export function useFetchChainContractDetail() {
  const { errorNotification } = useDispatchMessage();
  const [data, setData] = useState<IChainContractDetail | null>(null);

  const fetch = useCallback(async (params: { Id: ContractForInvoke['ContractId'] }) => {
    try {
      const result = await fetchChainContractDetail(params);
      setData(result.data.Response.Data);
    } catch (err) {
      errorNotification({
        title: '获取合约详情失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    detail: data,
    fetch,
  };
}

export function useModifyChainContractDetail() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(
    async (
      params: {
        Id: ContractForInvoke['ContractId'];
      } & Pick<ContractInstallRequest, 'Methods'>,
    ) => {
      try {
        await ModifyChainContractDetail(params);
        successNotification({
          description: '编辑合约成功',
        });
      } catch (err) {
        errorNotification({
          title: '编辑合约失败',
          description: (err as Error).Message,
        });
      }
    },
    [],
  );

  return {
    run,
  };
}

/**
 * 创建链
 */
export function useChainCreate() {
  const { errorNotification } = useDispatchMessage();
  const run = useCallback(async (params: ChainCreateRequest) => {
    try {
      await fetchChainCreate(params);
    } catch (err) {
      errorNotification({
        title: '创建失败',
        description: (err as Error).Message,
      });
      throw err;
    }
  }, []);

  return {
    run,
  };
}

export function useChainSubscribe() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const [res, setRes] = useState<any>(undefined);

  const run = useCallback(async (params: ChainSubscribeRequest) => {
    try {
      setRes((await fetchChainSubscribe(params)).data.Response?.Data?.Status);
      successNotification({ description: '订阅链成功' });
    } catch (err) {
      errorNotification({
        title: '订阅链失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
    res,
  };
}

export function useContractOperation() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(async (params: ContractFreezeRequest, type: ContractOperateType) => {
    try {
      if (type.value === 'freeze') {
        await fetchContractFreeze(params);
      } else if (type.value === 'revoke') {
        await fetchContractRevoke(params);
      } else {
        await fetchContractUnFreeze(params);
      }
      successNotification({ description: `${type.caption}成功` });
    } catch (err) {
      errorNotification({
        title: `${type.caption}失败`,
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
  };
}

export function useContractDelete() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(async (params: ContractDeleteRequest) => {
    try {
      await fetchContractDelete(params);
      successNotification({ description: `删除合约成功` });
    } catch (err) {
      errorNotification({
        title: `删除合约失败`,
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
  };
}

export function useContractInstall() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(async (params: ContractInstallRequest) => {
    try {
      await fetchContractInstall(params);
      successNotification({ description: '部署合约成功' });
    } catch (err) {
      errorNotification({
        title: '部署合约失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
  };
}

export function useContractUpgrade() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(async (params: ContractUpgradeRequest) => {
    try {
      await fetchContractUpgrade(params);
      successNotification({ description: '升级合约成功' });
    } catch (err) {
      errorNotification({
        title: '升级合约失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
  };
}

/**
 * 获取节点信息列表
 */
export function useFetchChainNodeList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<IChainNodeItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetch = useCallback(async (params: IChainNodeListRequest) => {
    try {
      const result = await fetchChainNodeList(params);
      setList(result.data.Response.GroupList);
      setTotalCount(result.data.Response.TotalCount);
    } catch (err) {
      errorNotification({
        title: '获取节点信息列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    list,
    totalCount,
    fetch,
  };
}

/**
 * 获取节点信息详情
 */
export function useFetchChainNodeDetail() {
  const { errorNotification } = useDispatchMessage();
  const [detail, setDetail] = useState<IChainNodeDetail | null>(null);

  const fetch = useCallback(async (params: IChainNodeDetailRequest) => {
    try {
      const result = await fetchChainNodeDetail(params);
      setDetail(result.data.Response.Data);
    } catch (err) {
      errorNotification({
        title: '获取节点信息详情失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    detail,
    fetch,
  };
}

/**
 * 区块链浏览器搜索
 */
export function useFetchHomePageSearch() {
  const { errorNotification } = useDispatchMessage();
  const [searchResult, setSearchResult] = useState<IHomePageSearch | null>(null);

  const fetch = useCallback(async (params: IHomePageSearchRequest) => {
    try {
      const result = await fetchHomePageSearch(params);
      setSearchResult(result.data.Response.Data);
    } catch (err) {
      errorNotification({
        title: '搜索失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    searchResult,
    fetch,
  };
}

export function useFetchChainAuthModify() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(async (params: ChainAuthRequest) => {
    try {
      await fetchChainAuth(params);
      successNotification({
        description: '配置保存成功',
      });
    } catch (err) {
      errorNotification({
        title: '配置保存失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
  };
}

export function useFetchChainReConfig() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(async (params: ChainReConfigRequest) => {
    try {
      await fetchChainReConfig(params);
      successNotification({
        description: '配置修改成功',
      });
    } catch (err) {
      errorNotification({
        title: '配置修改失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
  };
}

export function useFetchChainAuthList() {
  const { errorNotification } = useDispatchMessage();
  const [data, setData] = useState<ChainAuth[]>([]);
  const run = useCallback((chainId: string) => {
    try {
      fetchChainAuthList(chainId).then((res) => {
        setData(res.data.Response.GroupList);
      });
    } catch (err) {
      errorNotification({
        title: '获取链权限列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
    data,
  };
}

export function useFetchChainList() {
  const { errorNotification } = useDispatchMessage();
  const [data, setData] = useState<Chain[]>([]);
  const [loading, setLoading] = useState(false);
  const run = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchChainList();
      setData(res.data.Response.GroupList);
    } catch (err) {
      errorNotification({
        title: '获取链列表失败',
        description: (err as Error).Message,
      });
    }
    setLoading(false);
  }, []);

  return {
    run,
    data,
    loading,
  };
}

export function useFetchChainDownload() {
  const [loading, setLoading] = useState(false);
  const run = useCallback(async (chainId: string) => {
    setLoading(true);
    const res = await fetchChainDownload(chainId);
    setLoading(false);
    const name = res.data.Response.Data ?? '';
    saveAs(name, `${DOWNLOAD_FILE_URL}?name=${name}`);
  }, []);

  return {
    run,
    loading,
  };
}

export function useFetchChainConsensusList() {
  const [list, setList] = useState<ChainConsensus[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const run = useCallback(async (param: Parameters<typeof fetchChainConsensusList>[0]) => {
    const result = await fetchChainConsensusList(param);
    setList(result.data.Response.GroupList);
    setTotalCount(result.data.Response.TotalCount);
  }, []);

  return {
    list,
    totalCount,
    run,
  };
}

export function useFetchChainModes() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<ChainMode[]>([]);
  const run = useCallback(() => {
    try {
      fetchChainModes().then((res) => {
        setList(res.data.Response.Data);
      });
    } catch (err) {
      errorNotification({
        title: '获取链权限列表失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
    list,
  };
}

/**
 * 获取链管理员列表列表
 */
export function useFetchGetAdminList() {
  const [userList, setUserList] = useState<GetAdminResData[]>([]);
  const fetchUserList = useCallback(
    (chainId?: string) =>
      GetAdminList(chainId).then((res) => {
        setUserList(res.data.Response.GroupList);
      }),
    [],
  );

  return {
    userList,
    fetchUserList,
  };
}
