/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { forwardRef, useState, useEffect, useImperativeHandle, SyntheticEvent, Ref } from 'react';
import { Select, SelectOption } from 'tea-component';
import { useFetchChainList } from 'src/common/apis/chains/hooks';
export interface ChainSelectOption extends SelectOption {
  AutoReport?: number;
}
function ChainSelect(
  props: {
    onChange?: (
      value: string,
      item?: { event?: SyntheticEvent<Element, Event>; option?: ChainSelectOption | undefined },
    ) => void;
    value?: string;
  },
  ref?: Ref<any>,
) {
  const { onChange, value } = props;
  const { data, run } = useFetchChainList();
  const [options, setOptions] = useState<ChainSelectOption[]>([]);
  useEffect(() => {
    run();
  }, []);
  useImperativeHandle(ref, () => ({
    refreshList: () => {
      run();
    },
  }));
  useEffect(() => {
    const newoptions: ChainSelectOption[] = [];
    data.forEach((item) => {
      if (item.Status === 0 && item.Monitor === 1) {
        newoptions.push({
          value: item.ChainId,
          text: item.ChainName,
          AutoReport: item.AutoReport,
        });
      }
    });
    setOptions(newoptions);
    const hasNowSelect = newoptions.filter((item) => item.value === value);
    let select: ChainSelectOption | null = null;
    if (onChange) {
      if (hasNowSelect.length) {
        [select] = hasNowSelect;
      } else if (newoptions[0]) {
        [select] = newoptions;
      }
      if (select) {
        onChange(select.value, { option: select });
      }
    }
  }, [data]);
  return <Select options={options} onChange={onChange} value={value} />;
}

export default forwardRef(ChainSelect);
