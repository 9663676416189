/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useState } from 'react';
import { Layout, TabPanel, Tabs } from 'tea-component';
import { NodeCert } from './node-cert';
import { OrganizationCert } from './organization-cert';
import { UserCert } from './user-cert';
import { useQuery } from 'src/utils/hooks';
import { RouterProps } from 'react-router';
import GlossaryGuide from 'src/common/components/glossary-guide';
import { Tab } from 'tea-component/lib/tabs/TabProps';

const { Content } = Layout;

const tabs: (Tab & { component: Function })[] = [
  {
    id: 'organization',
    label: <GlossaryGuide title={'组织证书'} />,
    component: OrganizationCert,
  },
  {
    id: 'node',
    label: <GlossaryGuide title={'节点证书'} />,
    component: NodeCert,
  },
  {
    id: 'user',
    label: <GlossaryGuide title={'用户证书'} />,
    component: UserCert,
  },
];

export default function CertificatesPage({ history }: RouterProps) {
  const query = useQuery();
  const tabId = query.get('tab');
  const tabExist: boolean = tabId ? tabs.findIndex((item) => item.id === tabId) > -1 : false;
  const [activeTab, setActiveTab] = useState<string>(tabExist ? tabId! : 'organization');
  const modifyQueryTab = (tabId: string) => {
    history.push({
      search: `tab=${tabId}`,
    });
  };

  return (
    <>
      <Content.Header title={<GlossaryGuide title={'证书账户（PermissionedWithCert）'} />} />
      <Content.Body full>
        <Tabs
          tabs={tabs}
          ceiling
          className="tea-text-left"
          activeId={activeTab}
          onActive={(tab) => {
            setActiveTab(tab.id);
            modifyQueryTab(tab.id);
          }}
        >
          {tabs.map((tab) => (
            <TabPanel key={tab.id} id={tab.id}>
              <tab.component />
            </TabPanel>
          ))}
        </Tabs>
      </Content.Body>
    </>
  );
}
