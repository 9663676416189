/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { ErrorInfo } from 'react';
import { Status } from 'tea-component';

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, State> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Status
          icon="network"
          size={'l'}
          title={'网页出错'}
          description={
            <>
              请<a onClick={location.reload}>刷新</a>重试。
            </>
          }
        />
      );
    }
    return this.props.children;
  }
}
