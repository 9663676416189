/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { Ref, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Form, Input, Modal, Text } from 'tea-component';
import { Controller, useForm } from 'react-hook-form';
import formUtils from '../../../utils/form-utils';
import { useModifyUserPassword } from '../../../common/apis/accounts/hooks';
import { AppContext } from '../../../App';

interface IModalProps {
  visible: boolean;
  close: () => void;
}

interface IConfirmModalProps extends IModalProps {
  newPassword: string;
}

const { Password } = Input;

function ConfirmModal({ newPassword, visible, close }: IConfirmModalProps) {
  return (
    <>
      <Modal caption="重置密码提示" visible={visible} onClose={close}>
        <Modal.Body>
          <Text>请确认是否将改账号的密码重置为{newPassword}</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary">确定</Button>
          <Button onClick={close}>取消</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const initialData = {
  OldPassword: '',
  Password: '',
  confirmPassword: '',
};

export const ModifyPasswordModal = React.forwardRef(ModifyPasswordModalContainer);

export function ModifyPasswordModalContainer(props: {}, ref: Ref<any>) {
  const { user } = useContext(AppContext);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const onClose = useCallback(() => {
    setConfirmModalVisible(false);
  }, []);

  const {
    control,
    getValues,
    reset,
    formState: { errors, isValidating, isSubmitted, isValid },
  } = useForm({ mode: 'onBlur' });

  const { run: fetchModifyPassword } = useModifyUserPassword();

  const onSubmit = useCallback(() => {
    const values = getValues();
    fetchModifyPassword({
      UserId: user!.UserId,
      Password: values.Password,
      OldPassword: values.OldPassword,
    }).then(() => {
      setConfirmModalVisible(false);
    });
  }, []);
  useEffect(() => {
    reset();
  }, [confirmModalVisible]);

  useImperativeHandle(ref, () => ({
    showModal: () => setConfirmModalVisible(true),
  }));

  return (
    <>
      <ConfirmModal
        visible={confirmModalVisible}
        close={() => setConfirmModalVisible(false)}
        newPassword={getValues('newPassword')}
      />
      <Modal caption="修改密码" visible={confirmModalVisible} onClose={onClose}>
        <Modal.Body>
          <Form>
            <Controller
              name="OldPassword"
              control={control}
              defaultValue={initialData.OldPassword}
              rules={{
                validate: (OldPassword) => {
                  if (!OldPassword?.length) {
                    return '请输入原密码';
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="原密码"
                  required
                  status={formUtils.getStatus({ fieldState, isSubmitted, isValidating })}
                  message={errors.OldPassword?.message}
                >
                  <Password rules={false} placeholder="请输入原密码" size="full" {...field} />
                </Form.Item>
              )}
            />
            <Controller
              name="Password"
              control={control}
              defaultValue={initialData.Password}
              rules={{
                validate: (newPassword) => {
                  if (!newPassword.length) {
                    return '请输入新密码';
                  }
                  if (!/^[A-Za-z0-9]{6,16}$/.test(newPassword)) {
                    return '密码为6-16位字母数字组合';
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="新密码"
                  required
                  status={formUtils.getStatus({ fieldState, isSubmitted, isValidating })}
                  message={fieldState.error?.message}
                >
                  <Password rules={false} placeholder="请输入6-16位字母数字组合" size="full" {...field} />
                </Form.Item>
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue={initialData.confirmPassword}
              rules={{
                validate: (confirmPassword) => {
                  if (!confirmPassword.length) {
                    return '请输入新密码';
                  }
                  if (confirmPassword !== getValues('Password')) {
                    return '两次输入不一致';
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="新密码"
                  required
                  status={formUtils.getStatus({ fieldState, isSubmitted, isValidating })}
                  message={errors.confirmPassword?.message}
                >
                  <Password rules={false} placeholder="请再次输入密码" size="full" {...field} />
                </Form.Item>
              )}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" onClick={onSubmit} disabled={!isValid}>
            确定
          </Button>
          <Button onClick={onClose}>取消</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
