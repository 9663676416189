/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button, Text, Upload } from 'tea-component';
import { UPLOAD_FILE_URL } from '../apis/file';
import { UploadTypeEnum } from '../interface';
import { UploadProps } from 'tea-component/lib/upload/Upload';
import { FormControlProps } from 'tea-component/lib/form/FormControl';
import { buildXhrHeaders, handleResponseError } from '../../utils/request';
import { FileError } from 'react-dropzone';

export type TextTheme = 'text' | 'label' | 'weak' | 'strong' | 'primary' | 'success' | 'warning' | 'danger' | 'cost';

/**
 * 文件上传
 */
function UploadContainer(
  props: {
    onSuccess: (fileKey: string | null) => void;
  } & Omit<UploadProps & React.RefAttributes<HTMLSpanElement>, 'onSuccess' | 'children'>,
  parentRef: Ref<any>,
) {
  const { accept, onSuccess, ...rest } = props;
  const xhrRef = useRef<XMLHttpRequest | null>(null);
  const [status, setStatus] = useState<FormControlProps['status'] | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const handleStart = (file: File, { xhr }: { xhr: XMLHttpRequest }) => {
    setFile(file);
    setStatus('validating');
    onSuccess(null);
    xhrRef.current = xhr;
  };

  const handleDelete = useCallback(() => {
    setFile(null);
    setStatus(null);
    onSuccess(null);
  }, []);

  const handleSuccess = (result: any) => {
    if (result.Response.Data) {
      setStatus('success');
      onSuccess(result.Response.Data.FileKey);
    } else if (result.Response.Error) {
      handleResponseError(result.Response.Error);
      setStatus('error');
      onSuccess(null);
    }
  };

  const handleError = (e: Error) => {
    console.error(e);
    setStatus('error');
  };
  const handleBeforeUpload = (file: File, fileList: File[], isAccepted: boolean, reasons: FileError[]) => {
    if (!isAccepted) {
      console.error(reasons);
      setStatus('error');
      onSuccess(null);
    }
    return isAccepted;
  };
  const handleAbort = () => {
    if (xhrRef.current) {
      xhrRef.current.abort();
    }
    handleDelete();
  };

  useImperativeHandle(
    parentRef,
    () => ({
      status,
      setDefaultValue: (filename: string) => {
        setStatus('success');
        setFile(new File([''], filename));
      },
    }),
    [status],
  );

  return (
    <Upload
      withCredentials={true}
      action={UPLOAD_FILE_URL}
      accept={accept}
      onStart={handleStart}
      onSuccess={handleSuccess}
      onError={handleError}
      beforeUpload={handleBeforeUpload}
      ref={parentRef}
      headers={buildXhrHeaders()}
      send={(file, formData) => {
        formData.delete('file');
        formData.set('File', file);
        formData.set('FileName', file.name);
        formData.set('Type', String(UploadTypeEnum.Contract));
        return formData;
      }}
      {...rest}
    >
      {({ open, isDragging }) => (
        <Upload.Dragger
          filename={file?.name}
          description={file && file.size > 0 && <p>文件大小：{Math.floor(file.size / 1024)}K</p>}
          button={
            status === 'validating' ? (
              <Button type="link" onClick={handleAbort}>
                取消上传
              </Button>
            ) : (
              <>
                <Button type="link" onClick={open}>
                  重新上传
                </Button>
                <Button type="link" className="tea-ml-2n" onClick={handleDelete}>
                  删除
                </Button>
              </>
            )
          }
        >
          {isDragging ? (
            '释放鼠标'
          ) : (
            <>
              <Button type="link" onClick={open}>
                点击上传
              </Button>
              <Text>/拖拽到此区域</Text>
            </>
          )}
        </Upload.Dragger>
      )}
    </Upload>
  );
}

export const UploadFile = forwardRef(UploadContainer);
