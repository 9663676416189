/*
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 */
import React, { useEffect, useRef } from 'react';
import { Button, Form, Input, Modal, Select } from 'tea-component';
import { Control, Controller, useForm } from 'react-hook-form';
import formUtils, { Validator } from 'src/utils/form-utils';
import { useFetchImportCert } from 'src/common/apis/certificates/hooks';
import { UploadFile } from 'src/common/components/upload-file';
import { ChainAlgorithm, ICertItem } from 'src/common/apis/certificates/interface';
import { SizeType } from 'tea-component/lib/_type/Size';

const { getStatus } = formUtils;

interface IImportCertModalParam {
  visible: boolean;
  close: () => void;
  successCallback?: () => void;
}

const initialData = {
  RemarkName: '',
  OrgName: '',
};

export function ImportPublicModal({ visible, close, successCallback }: IImportCertModalParam) {
  const PrivateKey = useRef<any>(null);
  const PublicKey = useRef<any>(null);
  const { fetch } = useFetchImportCert();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValidating, isSubmitted, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      CertType: '1',
      Algorithm: ChainAlgorithm.NOT_CN.toString(),
    } as any,
  });

  const submit = async () => {
    const { RemarkName, CertType, PublicKey, PrivateKey, Algorithm } = getValues();
    await fetch({
      RemarkName,
      PublicKey,
      PrivateKey,
      Type: Number(CertType),
      Algorithm: +Algorithm as ICertItem['Algorithm'],
      ChainMode: 'public',
    });
    successCallback?.();
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  return (
    <Modal caption="导入账户" visible={visible} onClose={close}>
      <Modal.Body>
        <Form>
          <Controller
            name="RemarkName"
            defaultValue={initialData.RemarkName}
            control={control}
            rules={{
              validate: (RemarkName) => Validator.RemarkName(RemarkName),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="账户备注名"
                required
                status={getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.RemarkName?.message}
              >
                <Input placeholder="请输入账户备注名" size="full" {...field} />
              </Form.Item>
            )}
          />
          {renderChainCertTypeFormItem(control, isSubmitted, isValidating)}
          {renderChainAlgorithmFormItem(control, isSubmitted, isValidating)}
          <Controller
            name="PrivateKey"
            control={control}
            rules={{
              validate: async (value) => {
                if (!value && PrivateKey.current?.status === null) {
                  return '请上传账户私钥';
                }
                if (!value && PrivateKey.current?.status === 'error') {
                  return '上传私钥格式不正确';
                }
                return undefined;
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="账户私钥"
                required
                status={PrivateKey.current?.status}
                message={fieldState.error?.message}
              >
                <UploadFile
                  accept=".key"
                  {...field}
                  ref={PrivateKey}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
          <Controller
            name="PublicKey"
            control={control}
            rules={{
              validate: async (value) => {
                if (!value && PublicKey.current?.status === null) {
                  return '请上传账户公钥';
                }
                if (!value && PublicKey.current?.status === 'error') {
                  return '上传公钥格式不正确';
                }
                return undefined;
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="账户公钥"
                required
                status={PublicKey.current?.status}
                message={fieldState.error?.message}
              >
                <UploadFile
                  accept=".pem"
                  {...field}
                  ref={PublicKey}
                  onSuccess={(key: string | null) => {
                    setValue(field.name, key, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Form.Item>
            )}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={!isValid} onClick={handleSubmit(submit)}>
          确定
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}

// 密码算法
export function renderChainAlgorithmFormItem(
  control: Control,
  isSubmitted: boolean,
  isValidating: boolean,
  disabled = false,
  size: SizeType | 'auto' = 'full',
) {
  return (
    <Controller
      name="Algorithm"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <Form.Item
          label="密码算法"
          required
          status={getStatus({ fieldState, isSubmitted, isValidating })}
          message={fieldState.error?.message}
        >
          <Select
            disabled={disabled}
            size={size}
            options={[
              {
                text: '国密',
                value: ChainAlgorithm.CN.toString(),
              },
              {
                text: '非国密',
                value: ChainAlgorithm.NOT_CN.toString(),
              },
            ]}
            {...field}
          />
        </Form.Item>
      )}
    />
  );
}

// 账户用途
export function renderChainCertTypeFormItem(control: Control, isSubmitted: boolean, isValidating: boolean) {
  return (
    <Controller
      name="CertType"
      control={control}
      render={({ field, fieldState }) => (
        <Form.Item
          label="账户用途"
          required
          status={getStatus({ fieldState, isSubmitted, isValidating })}
          message={fieldState.error?.message}
        >
          <Select
            size="full"
            options={[
              {
                text: '节点账户',
                value: '1',
              },
              {
                text: '用户账户',
                value: '2',
              },
            ]}
            {...field}
          />
        </Form.Item>
      )}
    />
  );
}
