/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, PagingQuery, Table, TableColumn } from 'tea-component';
import { Link, useRouteMatch } from 'react-router-dom';
import { IChainBrowserTxItem } from 'src/common/apis/chains/interface';
import { PAGE_SIZE_OPTIONS, splitUrl } from 'src/utils/common';
import { useFetchBrowserContractDetail, useFetchDealList } from 'src/common/apis/chains/hooks';
import { formatDate } from 'src/utils/date';

const { pageable } = Table.addons;

export function ContractDetail({ id, chainId }: { id: number; chainId: string | null }) {
  const { url } = useRouteMatch();
  const chainMode = splitUrl(location.search).get('chainMode');
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const { detail, fetch: fetchDetail } = useFetchBrowserContractDetail();

  const { list, totalCount, fetch: fetchList } = useFetchDealList();
  const defaultColumn: TableColumn<IChainBrowserTxItem>[] = [
    {
      key: 'TxId',
      header: '交易ID',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const { TxId, Id } = record;
        return <Link to={`${url}?type=deal&Id=${Id}&chainMode=${chainMode}`}>{TxId}</Link>;
      },
    },
    {
      key: 'UserName',
      header: '发起方',
      render: (item: { UserName: string; Addr: string }) => item.Addr || item.UserName,
    },
    {
      key: 'ContractMethod',
      header: '方法名',
    },
    {
      key: 'ContractParameters',
      header: '内容',
      // eslint-disable-next-line react/display-name
      render: ({ ContractParameters }: IChainBrowserTxItem) =>
        JSON.stringify(ContractParameters) === 'null' ? '-' : JSON.stringify(ContractParameters),
    },
  ];

  useEffect(() => {
    if (!id || !chainId) return;
    fetchDetail({
      Id: id,
      ChainId: chainId,
    });
  }, [id, chainId, fetchDetail]);

  useEffect(() => {
    if (!chainId || !detail) return;
    fetchList({
      ChainId: chainId,
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
      ContractName: detail.ContractName,
    });
  }, [chainId, detail, pageQuery, fetchList]);
  return (
    <>
      <Card>
        <Card bordered style={{ border: 'none' }}>
          <Card.Body title="合约基本信息">
            <Form>
              <Form.Item label="合约名称">
                <Form.Text>{detail?.ContractName}</Form.Text>
              </Form.Item>
              <Form.Item label="创建者">
                <Form.Text>{detail?.Addr || detail?.Creator}</Form.Text>
              </Form.Item>
              <Form.Item label="创建交易ID">
                <Form.Text>{detail?.TxId}</Form.Text>
              </Form.Item>
              <Form.Item label="合约创建时间">
                <Form.Text>{formatDate(detail?.CreateTime)}</Form.Text>
              </Form.Item>
            </Form>
          </Card.Body>
        </Card>

        <Card bordered style={{ border: 'none' }} className="tea-mt-0">
          <Card.Body title="交易信息">
            <Table
              recordKey="Id"
              records={list}
              columns={defaultColumn}
              addons={[
                pageable({
                  recordCount: totalCount,
                  pageIndex: pageQuery.pageIndex,
                  pageSize: pageQuery.pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  onPagingChange: ({ pageIndex, pageSize }) =>
                    setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
                }),
              ]}
            />
          </Card.Body>
        </Card>
      </Card>
    </>
  );
}
