/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect } from 'react';
import { Button, Modal, Text } from 'tea-component';
import { useFetchCertDetail } from 'src/common/apis/certificates/hooks';
import { ValueOf } from 'src/common/interface';
import { CERTTYPE } from 'src/common/apis/certificates/interface';
import { downloadZipByTexts } from 'src/utils/common';

interface ICertDetailModalParam {
  visible: boolean;
  CertType: ValueOf<typeof CERTTYPE>;
  CertId: number | undefined;
  close: () => void;
  zipName?: string;
}

const CERT_TYPE_MAP = {
  0: '组织',
  1: '节点',
  2: '用户',
};

const CERT_USE_MAP = {
  SignCertDetail: {
    name: '签名证书',
    fileType: 'application/x-x509-ca-cert',
    fileName: 'SignCert.crt',
  },
  SignKeyDetail: {
    name: '签名私钥',
    fileType: 'application/pkcs8',
    fileName: 'SignKey.key',
  },
  TlsCertDetail: {
    name: 'TLS证书',
    fileType: 'application/x-x509-ca-cert',
    fileName: 'TlsCert.crt',
  },
  TlsKeyDetail: {
    name: 'TLS私钥',
    fileType: 'application/pkcs8',
    fileName: 'TlsKey.key',
  },
  NodeId: {
    name: 'NodeID',
    fileType: 'text/plain',
    fileName: 'NodeId.txt',
  },
};

export default function CertMultipleDetailModal({ visible, CertType, CertId, close, zipName }: ICertDetailModalParam) {
  const caption = `查看${CertType !== undefined ? CERT_TYPE_MAP[CertType] : ''}证书`;
  const { certDetail, fetch } = useFetchCertDetail();

  useEffect(() => {
    if (visible && CertId !== undefined) {
      fetch({ CertId });
    }
  }, [visible, CertId]);

  const download = useCallback(() => {
    if (certDetail) {
      // fetchDownload({ CertId }).then(() => {
      //   close();
      // });
      const list: any[] = [];
      const keys = Object.keys(certDetail) as (keyof typeof certDetail)[];
      if (keys.length === 0) {
        return;
      }
      keys.forEach((key) => {
        const data = CERT_USE_MAP[key];
        if (certDetail[key] && data) {
          list.push({
            data: certDetail[key],
            dataType: '',
            fileType: data.fileType,
            name: `${data.fileName}`,
          });
        }
      });
      downloadZipByTexts(list, `${zipName || CertId}.zip`);
      // console.log('download');
    }
  }, [certDetail, CertId, zipName]);
  return (
    <>
      <Modal caption={caption} visible={visible} onClose={close}>
        <Modal.Body>
          <div style={{ maxHeight: 400, overflow: 'auto' }}>
            {(Object.keys(certDetail) as (keyof typeof certDetail)[]).map((name) => {
              if (certDetail[name] && CERT_USE_MAP[name]) {
                return (
                  <>
                    <Text theme="text">
                      {CERT_TYPE_MAP[CertType]}
                      {CERT_USE_MAP[name].name}
                    </Text>
                    <p style={{ backgroundColor: '#eee', padding: 12, border: '1px solid #ccc', margin: '10px 0' }}>
                      <Text theme="label">{certDetail[name]} </Text>
                    </p>
                  </>
                );
              }
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" disabled={!certDetail} onClick={download}>
            下载
          </Button>
          <Button onClick={close}>取消</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
