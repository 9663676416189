/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { Layout, Menu } from 'tea-component';
import React from 'react';
import ChainOverview from './chain-overview';
import { Browser } from './browser';
import { Link, Redirect, Route, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { DetailPage } from './browser/detail-page';
import ChainAuth from './chain-auth';
import { ChainNode } from './node';
import { ChainNodeDetail } from './node/detail';
import { ChainOrganization } from './organization';
import ChainContracts from './chain-contracts';
import ChainInvokeContracts from './chain-invoke-contracts';
import ChainVotes from './chain-votes';
import ChainVotesDetail from './chain-votes-detail';
import ContractDetail from './contract/contract-detail';
import { splitUrl } from 'src/utils/common';
const { Body, Sider, Content } = Layout;
const menus: { title: string; href: string }[] = [
  {
    title: '区块链概览',
    href: '/overview',
  },
  {
    title: '合约管理',
    href: '/contracts',
  },
  {
    title: '上链管理',
    href: '/invoke-contracts',
  },
  {
    title: '投票管理',
    href: '/votes',
  },
  {
    title: '组织信息',
    href: '/organizations',
  },
  {
    title: '节点信息',
    href: '/nodes',
  },
  {
    title: '区块链浏览器',
    href: '/browser',
  },
];

export const ChainDetailContext = React.createContext<{ chainId: string | null }>({ chainId: null });
export default function ChainDetail() {
  const params = useLocation();
  const { url } = useRouteMatch();
  const currentPath = `/${params.pathname.split('/')[3]}`;
  const { id: chainId } = useParams<{ id: string }>();
  const chainMode = String(splitUrl(params.search).get('chainMode') || '');
  return (
    <Layout>
      <Body>
        <Sider>
          <Menu title={'区块链管理'}>
            {(chainMode === 'public' ? menus.filter((val) => val.title !== '组织信息') : menus).map((item) => (
              <Menu.Item
                selected={item.href === currentPath}
                key={item.title}
                title={item.title}
                render={(children) => <Link to={`${url}${item.href}?chainMode=${chainMode}`}>{children}</Link>}
              />
            ))}
          </Menu>
        </Sider>
        <Content>
          <ChainDetailContext.Provider value={{ chainId }}>{routes(chainMode)}</ChainDetailContext.Provider>
        </Content>
      </Body>
    </Layout>
  );
}

function routes(chainMode: string) {
  const { url } = useRouteMatch();
  return (
    <>
      <Route
        exact
        path={`${url}/`}
        render={() => <Redirect to={`${url}/overview?chainMode=${chainMode}`} />}
        key="index"
      />
      <Route exact path={`${url}/overview`} component={ChainOverview} key="ChainDetail" />
      <Route exact path={`${url}/auth`} component={ChainAuth} key="Auth" />
      <Route exact path={`${url}/organizations`} component={ChainOrganization} key="Organization" />
      <Route exact path={`${url}/nodes`} component={ChainNode} key="Nodes" />
      <Route exact path={`${url}/nodes/details/:id`} component={ChainNodeDetail} key="NodeDetail" />
      <Route exact path={`${url}/overview/auth`} component={ChainAuth} key="ChainAuth" />
      <Route exact path={`${url}/contracts`} component={ChainContracts} key="Contracts" />
      <Route exact path={`${url}/contracts/details/:id`} component={ContractDetail} key="ContractDetail" />
      <Route exact path={`${url}/browser`} component={Browser} key="Browser" />
      <Route exact path={`${url}/browser/details`} component={DetailPage} key="BrowserDetail" />
      <Route exact path={`${url}/invoke-contracts`} component={ChainInvokeContracts} key="InvokeContract" />
      <Route exact path={`${url}/votes`} component={ChainVotes} key="Votes" />
      <Route exact path={`${url}/votes/:id`} component={ChainVotesDetail} key="VotesDetail" />
    </>
  );
}
