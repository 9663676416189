/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { AxiosResponse } from 'axios';
import {
  IAccountStateParam,
  ICreateParam,
  IFetchUserListParam,
  IModifyPasswordParam,
  IResponseId,
  IUserItem,
} from './interface';
import { callApi } from '../../api';
import { ListResponse, Response, ResponseOk, UserLogin, UserResponse } from '../../interface';
import md5 from 'md5';

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E7%94%A8%E6%88%B7%E5%88%97%E8%A1%A8.md
 * @description GetUserList 用户列表
 */
export function fetchUserList(params: IFetchUserListParam): Promise<AxiosResponse<ListResponse<IUserItem>>> {
  return callApi('GetUserList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E6%96%B0%E5%A2%9E%E8%B4%A6%E5%8F%B7.md
 * @description AddUser 新增用户
 */
export function fetchCreateAccount(params: ICreateParam): Promise<AxiosResponse<ResponseOk>> {
  return callApi('AddUser', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81.md
 * @description ModifyPassword 修改密码
 */
export function fetchModifyPassword(params: IModifyPasswordParam): Promise<AxiosResponse<IResponseId>> {
  return callApi('ModifyPassword', {
    ...params,
    Password: md5(params.Password),
    OldPassword: md5(params.OldPassword),
  });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E9%87%8D%E7%BD%AE%E5%AF%86%E7%A0%81.md
 * @description ResetPassword 重置密码
 */
export function fetchResetPassword(params: IAccountStateParam): Promise<AxiosResponse<ResponseOk>> {
  return callApi('ResetPassword', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E5%90%AF%E7%94%A8.md
 * @description EnableUser 启用账户
 */
export function fetchEnableAccount(params: IAccountStateParam): Promise<AxiosResponse<ResponseOk>> {
  return callApi('EnableUser', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E7%A6%81%E7%94%A8.md
 * @description DisableUser 禁用账户
 */
export function fetchDisableAccount(params: IAccountStateParam): Promise<AxiosResponse<ResponseOk>> {
  return callApi('DisableUser', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E7%99%BB%E5%BD%95.md
 * Login
 * @param params
 */
export function fetchLogin(params: UserLogin): Promise<AxiosResponse<Response<UserResponse>>> {
  return callApi('Login', {
    ...params,
    Password: md5(params.Password),
  });
}

/**
 * https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E7%99%BB%E5%87%BA.md
 * Logout
 */
export function fetchLogout(): Promise<AxiosResponse<ResponseOk>> {
  return callApi('Logout');
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/%E8%8E%B7%E5%8F%96%E9%AA%8C%E8%AF%81%E7%A0%81.md
 * GetCaptcha
 */
export function fetchCaptcha(): Promise<AxiosResponse<Response<{ Content: string }>>> {
  return callApi('GetCaptcha');
}
