/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { useCallback, useState } from 'react';
import {
  fetchLogList,
  fetchPullErrorLog,
  fetchDownloadLogFile,
  fetchAutoReportLogFile,
  fetchReportLogFile,
} from './index';
import {
  IFetchLogListParam,
  ILogInfo,
  IPullParam,
  IDownloadLogParam,
  IAutoReportParam,
  IReportLogParam,
} from './interface';
import { getFileName } from 'src/utils/request';
import { useDispatchMessage } from 'src/utils/hooks';
import { saveBlobAs } from '../../../utils/common';
import { Error } from 'src/common/interface';

/**
 * 获取日志列表
 */
export function useFetchLogList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<ILogInfo[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const fetch = useCallback(async (params: IFetchLogListParam) => {
    try {
      const res = await fetchLogList(params);
      setList(res.data.Response.GroupList);
      setTotalCount(res.data.Response.TotalCount);
    } catch (err) {
      setList([]);
      setTotalCount(0);
      errorNotification({
        title: '获取日志列表失败',
        description: (err as Error).Message,
        duration: 0,
      });
    }
  }, []);
  return {
    list,
    totalCount,
    fetch,
  };
}

/**
 * 自动拉取链错误日志
 */
export function useFetchAutoReportLogFile() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IAutoReportParam) => {
    const action = params.Automatic ? '开启' : '关闭';
    if (!params.ChainId) {
      errorNotification({
        title: `请选择要操作的链`,
        description: `请选择${action}自动上报的链`,
      });
      return;
    }
    try {
      await fetchAutoReportLogFile(params);
      successNotification({
        description: `${action}自动上报成功`,
      });
    } catch (err) {
      errorNotification({
        title: `${action}自动上报失败`,
        description: (err as Error).Message,
      });
      throw err;
    }
  }, []);
  return {
    fetch,
  };
}

/**
 * 下载监控日志文件
 */
export function useFetchDownloadLogFile() {
  const { successNotification, errorNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IDownloadLogParam) => {
    try {
      const result = await fetchDownloadLogFile(params);
      const { data: blob, headers } = result;
      const filename = getFileName(headers);
      saveBlobAs(filename, new Blob([blob]));
      successNotification({
        description: '开始下载证书',
      });
    } catch (err) {
      errorNotification({
        title: '下载证书失败',
        description: (err as Error).Message,
      });
    }
  }, []);
  return {
    fetch,
  };
}

/**
 * 拉取链错误日志
 */
export function useFetchPullErrorLog() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IPullParam) => {
    try {
      await fetchPullErrorLog(params);
      successNotification({
        description: '拉取链错误日志成功',
      });
    } catch (err) {
      errorNotification({
        title: '拉取链错误日志失败',
        description: (err as Error).Message,
      });
      throw err;
    }
  }, []);
  return {
    fetch,
  };
}

/**
 * 上报监控日志文件
 */
export function useFetchReportLogFile() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IReportLogParam) => {
    try {
      await fetchReportLogFile(params);
      successNotification({
        description: '上报监控日志文件成功',
      });
    } catch (err) {
      errorNotification({
        title: '上报监控日志文件失败',
        description: (err as Error).Message,
      });
    }
  }, []);
  return {
    fetch,
  };
}
