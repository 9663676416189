/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { MouseEvent, useCallback, useEffect, useRef } from 'react';
import { Badge, Button, Col, Form, H3, Justify, Layout, notification, Row, Dropdown, List } from 'tea-component';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import ChainDetail from './chain-detail';
import ChainNew from './chain-new';
import ChainSubscribe from './chain-subscribe';
import { useGotoClick } from '../../utils/hooks';
import { formatDate } from '../../utils/date';
import { useFetchChainList } from '../../common/apis/chains/hooks';
import { Chain } from '../../common/apis/chains/interface';
import { fetchChainDelete, fetchReSubChain, fetchPauseChain } from '../../common/apis/chains';
import { Error } from '../../common/interface';
import { ConfirmModal } from '../../common/common';
import { stringifyQueryParams } from '../../utils/common';

const { Content } = Layout;
export default function ChainsPage() {
  return <>{routes()}</>;
}

function routes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.url} component={ChainList} key="ChainList" />
      <Route exact path={`${match.url}/new`} component={ChainNew} key="ChainNew" />
      <Route exact path={`${match.url}/subscribe`} component={ChainSubscribe} key="ChainSubscribe" />
      <Route path={`${match.url}/:id`} component={ChainDetail} key="ChainDetail" />
    </Switch>
  );
}

function ChainList() {
  const { run: fetchChainList, data: chains } = useFetchChainList();
  const history = useHistory();
  const handleNewClick = useGotoClick('/chains/new');
  const handleSubscribeClick = useGotoClick('/chains/subscribe');
  const handleDetailClick = useCallback((chain: Chain) => {
    if (chain.Status === 1) {
      history.push(
        `/chains/new?${stringifyQueryParams({
          chainId: chain.ChainId,
          chainName: chain.ChainName,
          step: 'deploy',
        })}`,
      );
    } else {
      history.push(
        `/chains/${chain.ChainId}?${stringifyQueryParams({
          chainMode: chain.ChainMode,
        })}`,
      );
    }
  }, []);

  const confirmRef = useRef();
  useEffect(() => {
    fetchChainList();
  }, []);

  const handleChainDelete = useCallback(
    (item: Chain, e: MouseEvent<Element>) => {
      e.stopPropagation();
      // @ts-ignore
      confirmRef.current.show({
        confirm: () => {
          fetchChainDelete({ ChainId: item.ChainId })
            .then(() => {
              fetchChainList();
            })
            .catch((err) => {
              notification.error({
                title: '删除失败',
                description: (err as Error).Message,
              });
            });
        },
      });
    },
    [confirmRef],
  );

  // 重新订阅链
  const handleReSubChain = useCallback((item: Chain, e: MouseEvent<Element>) => {
    e.stopPropagation();
    fetchReSubChain({ ChainId: item.ChainId })
      .then(() => {
        notification.success({
          description: '重新订阅链成功',
        });
        fetchChainList();
      })
      .catch((err) => {
        notification.error({
          title: '重新订阅链失败',
          description: (err as Error).Message,
        });
      });
  }, []);
  const handlePauseChain = useCallback((item: Chain, e: MouseEvent<Element>) => {
    e.stopPropagation();
    fetchPauseChain({ ChainId: item.ChainId })
      .then(() => {
        notification.success({
          description: '暂停订阅链成功',
        });
        fetchChainList();
      })
      .catch((err) => {
        notification.error({
          title: '暂停订阅链失败',
          description: (err as Error).Message,
        });
      });
  }, []);

  return (
    <>
      <Content.Header title="区块链管理" />
      <Content.Body full>
        <Justify
          left={
            <>
              <Button type="primary" onClick={handleNewClick}>
                新建区块链
              </Button>
              <Button type="primary" onClick={handleSubscribeClick}>
                订阅区块链
              </Button>
            </>
          }
        />
        <Row className="tea-mt-3n">
          {chains.map((item) => (
            <Col span={8} key={item.ChainId}>
              <div className={'tea-grid__box'} onClick={() => handleDetailClick(item)}>
                <div className="_cm-blockChain-item">
                  <div className={'_cm-blockChain-item-hd'}>
                    <div className={'_cm-blockChain-item-hd__item'}>
                      <div className={'blockChain-title'}>组织数</div>
                      <div className={'blockChain-value'}>{item.OrgNum}</div>
                    </div>
                    <div className={'_cm-blockChain-item-hd__item'}>
                      <div className={'blockChain-title'}>节点总数</div>
                      <div className={'blockChain-value'}>{item.NodeNum}</div>
                    </div>
                    <div className={'_cm-blockChain-item-hd__item'}></div>
                  </div>
                  <div className={'_cm-blockChain-item-bd'}>
                    <H3>
                      {item.ChainName}
                      <span className={'blockChain-bd-subtitle'}></span>
                      {item.Status === 1 && (
                        <Badge dark theme="danger" className={'chain-list-badge'}>
                          {'未订阅'}
                        </Badge>
                      )}
                      {item.Status === 2 && (
                        <Badge dark theme="danger" className={'chain-list-badge'}>
                          {'已失效'}
                        </Badge>
                      )}
                      {item.Status === 3 && (
                        <Badge dark theme="danger" className={'chain-list-badge'}>
                          {'已暂停'}
                        </Badge>
                      )}
                    </H3>
                    <Form className={'tea-mt-3n'}>
                      <Form.Item label={'区块链ID'}>
                        <Form.Text>{item.ChainId}</Form.Text>
                      </Form.Item>
                      <Form.Item label={'账户模式'}>
                        <Form.Text>{item.ChainMode}</Form.Text>
                      </Form.Item>
                      <Form.Item label={'创建时间'}>
                        <Form.Text>{formatDate(item.CreateTime)}</Form.Text>
                      </Form.Item>
                    </Form>
                  </div>
                  <Dropdown
                    clickClose={true}
                    className="chain-item-delete"
                    button={<Button type="icon" icon="more" />}
                    placement="bottom-end"
                    appearance="pure"
                    boxStyle={{ minWidth: 100 }}
                  >
                    {(close) => (
                      <List type="option">
                        {item.Status !== 1 && (
                          <List.Item
                            onClick={(e) => {
                              close();
                              handleReSubChain(item, e);
                            }}
                          >
                            重新订阅
                          </List.Item>
                        )}
                        {item.Status === 0 && (
                          <List.Item
                            onClick={(e) => {
                              close();
                              handlePauseChain(item, e);
                            }}
                          >
                            暂停订阅
                          </List.Item>
                        )}
                        <List.Item
                          onClick={(e) => {
                            close();
                            handleChainDelete(item, e);
                          }}
                        >
                          删除
                        </List.Item>
                      </List>
                    )}
                  </Dropdown>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Content.Body>
      <ConfirmModal ref={confirmRef} title={'删除确认'}>
        请确定是否要删除该区块链网络，删除后如还想获取该链的信息，需要重新订阅。
      </ConfirmModal>
    </>
  );
}
