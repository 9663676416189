/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Error, ErrorCode, ErrorResponse, Response } from 'src/common/interface';
import { LocalSetting } from 'src/common/local-setting';
import { useDispatchMessage } from './hooks';

const errorHandlerMap = new Map<(status: number) => boolean, () => void>();

errorHandlerMap.set(
  (status) => status === 504,
  () => {
    const { errorNotification } = useDispatchMessage();
    errorNotification({
      title: '请求超时，请重试',
    });
  },
);
errorHandlerMap.set(
  (status) => status === 503 || status === 502,
  () => {
    const { errorNotification } = useDispatchMessage();
    errorNotification({
      title: '服务暂不可用',
    });
  },
);

errorHandlerMap.set(
  (status) => status === 401 || status === 403,
  () => {
    const { errorNotification } = useDispatchMessage();
    errorNotification({
      title: '权限不足，跳转到首页',
    });
    LocalSetting.logout();
    window.location.href = '/login';
  },
);

export const BASE_URL = 'http://192.168.1.108';

export const API_URL = `${process.env.NODE_ENV === 'development' ? '' : ''}/chainmaker`;
export const DOWNLOAD_FILE_URL = `${API_URL}/download/`;

export const buildXhrHeaders = () => ({
  token: LocalSetting.getToken() as string,
});

export const handleResponseError = (error: Error) => {
  const { errorNotification } = useDispatchMessage();

  if (error.Code === ErrorCode.TokenExpired) {
    errorNotification({
      title: '登录失效',
      description: '请重新登录',
      duration: 2000,
      onClose: () => {
        LocalSetting.logout();
        window.location.href = '/login';
      },
    });
    return true;
  }
};

export function $axios({ params, data, headers, ...rest }: AxiosRequestConfig): Promise<AxiosResponse<any>> {
  const RES: AxiosRequestConfig = {
    method: 'POST',
    baseURL: API_URL,
    params,
    data,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      ...headers,
    },
    ...rest,
  };

  // 请求拦截
  axios.interceptors.request.use((config) => {
    if (LocalSetting.getToken()) {
      Object.assign(config.headers, { token: LocalSetting.getToken() });
    }
    return config;
  });

  // 响应拦截
  axios.interceptors.response.use();

  return new Promise((resolve, reject) => {
    axios(RES)
      .then((res: AxiosResponse<ErrorResponse | Response<any>>) => {
        const response = (res as AxiosResponse<ErrorResponse>).data.Response;
        const error = response?.Error;
        if (error) {
          handleResponseError(error);
          reject(error);
        } else {
          resolve(res);
        }
      })
      .catch((err: AxiosError) => {
        const status = err.response?.status as number;
        for (const fn of errorHandlerMap.keys()) {
          fn(status) && errorHandlerMap.get(fn)!();
        }
        // handler error
        reject(err);
      });
  });
}

export function getFileName(header: any) {
  return (header['Content-Disposition'] || header['content-disposition'])?.split('filename=')[1] || '';
}
