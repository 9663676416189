/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { AxiosResponse } from 'axios';
import { callApi } from 'src/common/api';
import { IResponseId, ListResponse, Response, ResponseOk } from 'src/common/interface';
import {
  ICertItem,
  IDetailCertParam,
  IDetailCertResponse,
  IDownloadCertParam,
  IFetchCertListParam,
  IGenerateCertParam,
  IImportCertParam,
} from './interface';

/**
 * GetCertList 获取证书列表
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86/%E8%AF%81%E4%B9%A6%E5%88%97%E8%A1%A8.md
 */
export function fetchCertList(params: IFetchCertListParam): Promise<AxiosResponse<ListResponse<ICertItem>>> {
  return callApi('GetCertList', params);
}

/**
 * GenerateCert 申请证书
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86/%E7%94%B3%E8%AF%B7%E8%AF%81%E4%B9%A6.md
 */
export function fetchGenerateCert(params: IGenerateCertParam): Promise<AxiosResponse<IResponseId>> {
  return callApi('GenerateCert', params);
}

/**
 * OneGenerate 生成证书
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.3.0_sdy/interface/%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86/%E4%B8%80%E9%94%AE%E7%94%9F%E6%88%90%E8%AF%81%E4%B9%A6.md
 */
export function fetchOneGenerateCert(): Promise<AxiosResponse<ResponseOk>> {
  return callApi('OneGenerate');
}

/**
 * DownloadCert 下载证书
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86/%E4%B8%8B%E8%BD%BD%E8%AF%81%E4%B9%A6.md
 */
export function fetchDownloadCert(params: IDownloadCertParam): Promise<AxiosResponse<Blob>> {
  return callApi('DownloadCert', params);
}

/**
 * ImportCert 导入证书
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86/%E5%AF%BC%E5%85%A5%E8%AF%81%E4%B9%A6.md
 */
export function fetchImportCert(params: IImportCertParam): Promise<AxiosResponse<IResponseId>> {
  return callApi('ImportCert', params);
}

/**
 * DeleteAccount 删除证书
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/develop/interface/%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86/%E5%88%A0%E9%99%A4%E8%B4%A6%E6%88%B7.md
 */
export function fetchDeleteCert(params: IDetailCertParam): Promise<AxiosResponse<Response<IDetailCertResponse>>> {
  return callApi('DeleteAccount', params);
}

/**
 * GetCert 查看证书
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E8%AF%81%E4%B9%A6%E7%AE%A1%E7%90%86/%E6%9F%A5%E7%9C%8B%E8%AF%81%E4%B9%A6.md
 */
export function fetchDetailCert(params: IDetailCertParam): Promise<AxiosResponse<Response<IDetailCertResponse>>> {
  return callApi('GetCert', params);
}
