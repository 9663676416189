/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { CertNode } from 'src/common/apis/certificates/interface';
import { useFetchCertNodeList } from 'src/common/apis/chains/hooks';
import { ChainModes } from 'src/common/apis/chains/interface';
import { Table, Transfer } from 'tea-component';

interface NodeSelectByRoleProps {
  onChange: (nodes: any[]) => void;
  nodeRole?: 0 | 1;
  algorithm: string;
  chainMode?: ChainModes;
}

// 选取不同类型节点节点
export default function NodeSelectForPk({ onChange, chainMode, algorithm, nodeRole }: NodeSelectByRoleProps) {
  const { selectable, removeable, scrollable } = Table.addons;
  const { nodeList, run: fetchNodeList } = useFetchCertNodeList();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<CertNode[]>([]);
  useEffect(() => {
    setSelectedOptions([]);
    // onChange([]);
    if (nodeRole !== undefined && chainMode !== undefined) {
      fetchNodeList({
        NodeRole: nodeRole,
        ChainMode: chainMode,
        Algorithm: Number(algorithm),
      });
    }
  }, [nodeRole, chainMode, algorithm]);

  useEffect(() => {
    const selectNode = nodeList.filter((item) => selectedOptions.includes(item.NodeName));
    setSelectedNodes(selectNode);
    onChange([
      {
        NodeList: selectNode,
        OrgId: '',
      },
    ]);
  }, [selectedOptions]);

  const onRemove = useCallback(
    (name) => {
      setSelectedOptions(selectedOptions.filter((item) => item !== name));
    },
    [selectedOptions],
  );

  return (
    <Transfer
      style={{
        width: '800px',
      }}
      leftCell={
        <Transfer.Cell scrollable={false} title="选择节点">
          <Table
            records={nodeList}
            recordKey="NodeName"
            columns={[
              {
                key: 'NodeName',
                header: '节点名称',
              },
            ]}
            addons={[
              scrollable({
                maxHeight: 340,
                onScrollBottom: () => console.log('到达底部'),
              }),
              selectable({
                value: selectedOptions,
                onChange: setSelectedOptions,
                rowSelect: true,
              }),
            ]}
          />
        </Transfer.Cell>
      }
      rightCell={
        <Transfer.Cell title={`已选择 (${selectedOptions.length})`}>
          <Table
            records={selectedNodes}
            recordKey="NodeName"
            columns={[
              {
                key: 'NodeName',
                header: '节点名称',
              },
            ]}
            addons={[removeable({ onRemove })]}
          />
        </Transfer.Cell>
      }
    />
  );
}
