/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Button, Modal, Text } from 'tea-component';
import { useFetchResetPassword } from 'src/common/apis/accounts/hooks';

interface IModalProps {
  visible: boolean;
  close: () => void;
  userId: number | undefined;
  successCallback?: () => void;
}

export function ResetPasswordModal({ visible, close, userId, successCallback }: IModalProps) {
  const { fetch } = useFetchResetPassword();
  const submit = async () => {
    if (!userId) return;
    await fetch({ UserId: userId });
    successCallback?.();
  };
  return (
    <>
      <Modal caption="确认提示" visible={visible} onClose={close}>
        <Modal.Body>
          <Text>确认是否将该账号的密码重置</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" onClick={submit}>
            确定
          </Button>
          <Button onClick={close}>取消</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
