/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect } from 'react';
import { Button, Form, Input, Modal } from 'tea-component';
import { Controller, ControllerFieldState, useForm } from 'react-hook-form';
import md5 from 'md5';
import { useFetchCreateUser } from 'src/common/apis/accounts/hooks';
import { ICreateParam } from 'src/common/apis/accounts/interface';
import { Validator } from '../../../utils/form-utils';

interface IModalProps {
  visible: boolean;
  close: () => void;
  successCallback?: () => void;
}

const { Password } = Input;

const initialData: ICreateParam & { ConfirmPassword: string } = {
  Name: '',
  UserName: '',
  Password: '',
  ConfirmPassword: '',
};

export function CreateAccountModal({ visible, close, successCallback }: IModalProps) {
  const { fetch } = useFetchCreateUser();
  const submit = async (values: ICreateParam & { ConfirmPassword: string }) => {
    await fetch({
      Name: values.Name,
      UserName: values.UserName,
      Password: md5(values.Password),
    });
    successCallback?.();
  };

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isValidating, isSubmitted, isValid },
  } = useForm({ mode: 'onBlur' });

  const getStatus = (meta: ControllerFieldState) => {
    if (!meta.isDirty && !isSubmitted) {
      return undefined;
    }
    return meta.invalid ? 'error' : 'success';
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  return (
    <>
      <Modal caption="新增账号" onClose={close} visible={visible} size="m">
        <Modal.Body>
          <Form>
            <Controller
              name="Name"
              control={control}
              defaultValue={initialData.Name}
              rules={{
                validate: (name) => Validator.validateName(name),
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="姓名"
                  required
                  status={isValidating ? 'validating' : getStatus(fieldState)}
                  message={errors.Name?.message}
                >
                  <Input
                    autoComplete="off"
                    defaultValue={initialData.Name}
                    placeholder="请输入姓名"
                    size="full"
                    {...field}
                  />
                </Form.Item>
              )}
            />
            <Controller
              name="UserName"
              control={control}
              defaultValue={initialData.UserName}
              rules={{
                validate: (username) => Validator.validateUserName(username),
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="用户名"
                  required
                  status={isValidating ? 'validating' : getStatus(fieldState)}
                  message={errors.UserName?.message}
                >
                  <Input
                    placeholder="请输入用户名"
                    defaultValue={initialData.UserName}
                    autoComplete="off"
                    size="full"
                    {...field}
                  />
                </Form.Item>
              )}
            />
            <Controller
              name="Password"
              control={control}
              defaultValue={initialData.Password}
              rules={{
                validate: (password) => Validator.validatePassword(password),
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="登录密码"
                  required
                  status={isValidating ? 'validating' : getStatus(fieldState)}
                  message={errors.Password?.message}
                >
                  <Password
                    placeholder="请输入6~16位数字和大小写字母以及~,!,@,#,$,%,^,. 等字符组合。"
                    size="full"
                    autoComplete="off"
                    defaultValue={initialData.Password}
                    rules={false}
                    {...field}
                  />
                </Form.Item>
              )}
            />
            <Controller
              name="ConfirmPassword"
              control={control}
              defaultValue={initialData.ConfirmPassword}
              rules={{
                validate: (confirmPassword) => {
                  if (!confirmPassword.length) {
                    return '请输入确认密码';
                  }
                  if (confirmPassword !== getValues('Password')) {
                    return '两次输入不一致';
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="确认密码"
                  required
                  status={isValidating ? 'validating' : getStatus(fieldState)}
                  message={errors.ConfirmPassword?.message}
                >
                  <Password
                    placeholder="请再次输入密码"
                    size="full"
                    autoComplete="off"
                    defaultValue={initialData.ConfirmPassword}
                    rules={false}
                    {...field}
                  />
                </Form.Item>
              )}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" disabled={!isValid} onClick={handleSubmit(submit)}>
            确定
          </Button>
          <Button htmlType="button" onClick={close}>
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
