/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Alert, Button, Card, Layout } from 'tea-component';

const { Content } = Layout;

export default function FaqPage() {

  return (
    <div>
      <Content.Header title="问题反馈"/>
      <Content.Body full>
        <Card>
          <Card.Body>
            <Alert>如果您在使用长安链过程中，遇到一些问题，或者对产品有建议，可按照issue模版，提交issue到长安链开源代码仓库，官方运营团队每个工作日将集中收集处理用户反馈的内容。也可加入长安链社区微信群，和其他开发者讨论。</Alert>
            <div className={'flex faq-content'}>
              <div>
                <img src={'static/img/gitlab.jpeg'}/>
                <div>
                  <Button className={'fullwidth'} type={'primary'}
                          onClick={() => window.open('https://git.chainmaker.org.cn/chainmaker/issue/-/issues')}>去提issue</Button>
                </div>
              </div>
              <div>
                <img src={'static/img/qrcode.png'}/>
                <h1>
                  添加长安链小助理微信加入社区微信群
                </h1>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Content.Body>
    </div>
  );
}
