/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Button, Modal, Text } from 'tea-component';
import { useFetchDisableUser } from 'src/common/apis/accounts/hooks';

interface IModalProps {
  visible: boolean;
  close: () => void;
  userId: number | undefined;
  successCallback?: () => void;
}

export function DisableAccountModal({ visible, close, userId, successCallback }: IModalProps) {
  const { fetch } = useFetchDisableUser();
  const submit = async () => {
    if (!userId) return;
    await fetch({ UserId: userId });
    successCallback?.();
  };
  return (
    <>
      <Modal caption="禁用账号提示" visible={visible} onClose={close}>
        <Modal.Body>
          <Text>禁用后该账号将无法登录，请确认禁用该账号？</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" onClick={submit}>
            确定
          </Button>
          <Button onClick={close}>取消</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
