/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { useFetchCertUserList } from 'src/common/apis/chains/hooks';
import { CertUser, ChainModes } from 'src/common/apis/chains/interface';
import { Table, Transfer } from 'tea-component';

// 选取不同类型节点节点
export default function NodeSelectForPk({
  onChange,
  chainMode,
  algorithm,
}: {
  onChange: (list: string[]) => void;
  chainMode: ChainModes;
  algorithm?: string;
}) {
  const { selectable, removeable, scrollable } = Table.addons;
  const { list: adminList, run: fetchAdminList } = useFetchCertUserList();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<CertUser[]>([]);
  useEffect(() => {
    fetchAdminList({ ChainMode: chainMode, Algorithm: Number(algorithm) });
  }, [chainMode, algorithm]);

  const onRemove = useCallback(
    (name) => {
      setSelectedOptions(selectedOptions.filter((item) => item !== name));
    },
    [selectedOptions],
  );

  useEffect(() => {
    const selectAdmin = adminList.filter((item) => selectedOptions.includes(item.UserName));
    setSelectedAdmin(selectAdmin);
    onChange(selectedOptions);
  }, [selectedOptions]);

  return (
    <Transfer
      style={{
        width: '800px',
      }}
      leftCell={
        <Transfer.Cell scrollable={false} title="选择管理员">
          <Table
            records={adminList}
            recordKey="UserName"
            columns={[
              {
                key: 'UserName',
                header: '管理员名称',
              },
            ]}
            addons={[
              scrollable({
                maxHeight: 340,
                onScrollBottom: () => console.log('到达底部'),
              }),
              selectable({
                value: selectedOptions,
                onChange: setSelectedOptions,
                rowSelect: true,
              }),
            ]}
          />
        </Transfer.Cell>
      }
      rightCell={
        <Transfer.Cell title={`已选择 (${selectedOptions.length})`}>
          <Table
            records={selectedAdmin}
            recordKey="UserName"
            columns={[
              {
                key: 'UserName',
                header: '管理员名称',
              },
            ]}
            addons={[removeable({ onRemove })]}
          />
        </Transfer.Cell>
      }
    />
  );
}
