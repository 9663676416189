/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */

import React, { useCallback, useImperativeHandle, useState } from 'react';

import { Button, Modal } from 'tea-component/es';

function ConfirmModalComponent({ title, children }: {
  title: string;
  children: React.ReactNode;
}, ref: any) {
  const [visible, setVisible] = useState(false);
  const [conf, setConf] = useState<{
    close?: () => void;
    confirm?: () => void;
  }>({});
  useImperativeHandle(ref, () => ({
    show: (p: {
      close?: () => void;
      confirm?: () => void;
    }) => {
      setConf(p);
      setVisible(true);
    },
  }));

  const onConfirm = useCallback(() => {
    conf.confirm?.();
    setVisible(false);
  }, [conf]);

  const onCancel = useCallback(() => {
    conf.close?.();
    setVisible(false);
  }, [conf]);

  return <Modal visible={visible} caption={title} onClose={onCancel} disableCloseIcon>
    <Modal.Body>{children} </Modal.Body>
    <Modal.Footer>
      <Button type="primary" onClick={onConfirm}>
        确定
      </Button>
      <Button type="weak" onClick={onCancel}>
        取消
      </Button>
    </Modal.Footer>
  </Modal>;
}

export const ConfirmModal = React.forwardRef(ConfirmModalComponent);

/**
 * 原生超链接，外链
 */
export function ExternalLink(p: any) {
  return <a target={'_blank'} {...p}></a>;
}
