/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { CertNode } from './apis/certificates/interface';

export type ValueOf<T> = T[keyof T];
export type NoUndefinedField<T> = Exclude<T, undefined>;

export interface Response<T> {
  Response: {
    RequestId: string;
    Data: T;
  };
}

export const ErrorCode = {
  TokenExpired: 'ErrorTokenExpired',
  AdminNotImport: 'ErrorAdminNotImport',
  AccountExisted: 'ErrorAccountExisted', // 账户已存在
  AccountKeyMatch: 'ErrorAccountKeyMatch', // 不匹配
  AlgorithmMatch: 'ErrorAlgorithmMatch', // 算法不匹配
};

export type Error = {
  Code: ValueOf<typeof ErrorCode> | unknown;
  Message: string;
};

export interface ErrorResponse {
  Response: {
    Error: Error;
  };
}

export interface ListResponse<T> {
  Response: {
    ResquestId: string;
    TotalCount: number;
    GroupList: T[];
  };
}

export interface UserResponse {
  UserId: number;
  UserName: string;
  Token: string;
}

export type ResponseOk = Response<{ Status: 'OK' }>;

export interface UserItem {
  Id: number;
  UserName: string;
  Name: string;
  Status: 0 | 1;
  CreateTime: Date;
}

export interface UserLogin {
  UserName: string;
  // MD5加密
  Password: string;
  Captcha: string;
}

export interface IResponseId {
  ResponseId: string;
}

export type ChainConsensus = {
  ConsensusName: string;
  ConsensusType: number;
};

export interface ChainsState {
  ConsensusList: ChainConsensus[];
  CertOrgList: {
    OrgId: string;
    OrgName: string;
    NodeList: CertNode[];
  }[];
}

export interface PageQuery {
  // 从0开始
  PageNum: number;
  // 最大100
  PageSize: number;
}

export enum UploadTypeEnum {
  Certificate,
  Contract,
}

export type UploadFileRequest = {
  File: File;
  FileName: string;
  Type: UploadTypeEnum;
};

// -1代表所有
export type TableFilterField<T> = T | -1;
