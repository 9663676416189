/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { Card, Form, H3, Layout } from 'tea-component';
import React, { useEffect } from 'react';
import { useFetchVoteDetail } from '../../common/apis/chains/hooks';
import { useParams, useHistory } from 'react-router-dom';
import { VoteStatus } from './chain-votes';
import { ChainAuthTypes } from './chain-auth';
import { splitUrl } from 'src/utils/common';
const { Content } = Layout;
const { Text } = Form;
export default function ChainVotesDetail() {
  const { id: voteId } = useParams<{ id: string }>();
  const { data, run } = useFetchVoteDetail();
  const history = useHistory();
  const isPublic = splitUrl(location.search).get('chainMode') === 'public';
  useEffect(() => {
    run(+voteId);
  }, []);
  return (
    <Content>
      <Content.Header title="区块链管理/投票管理/投票详情" showBackButton onBackButtonClick={history.goBack} />
      <Content.Body full>
        <Card>
          <Card.Body>
            <H3>投票议案</H3>
            <Form>
              <Form.Item label="投票事件">
                <Text>{data && ChainAuthTypes[data.VoteType]?.title}</Text>
              </Form.Item>
              <Form.Item label={isPublic ? '发起管理员' : '发起组织'}>
                <Text>{data?.StartOrgName}</Text>
              </Form.Item>
              <Form.Item label="投票状态">
                <VoteStatus record={data} />
              </Form.Item>
              <Form.Item label="议案详情">
                <Text>{data?.VoteDetail}</Text>
              </Form.Item>
              <Form.Item label="投票原因">
                <Text>{data?.Reason}</Text>
              </Form.Item>
            </Form>
            <H3 className={'tea-mt-5n'}>投票情况</H3>
            <Form>
              <Form.Item label="投同意票">
                <Text>{data?.PassOrgs?.join()}</Text>
              </Form.Item>
              <Form.Item label="同意比例">
                <Text>{data?.PassPercent}</Text>
              </Form.Item>
              <Form.Item label="未投票">
                <Text>{data?.UnVotedOrgs?.join()}</Text>
              </Form.Item>
              <Form.Item label="未投票比例">
                <Text>{data?.UnVotedPercent}</Text>
              </Form.Item>
            </Form>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  );
}
