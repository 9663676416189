/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Justify, PagingQuery, SearchBox, Table, TableColumn } from 'tea-component';
import { formatDate } from 'src/utils/date';
import { ICertItem, IDetailCertParam } from 'src/common/apis/certificates/interface';
import { GenerateUserCertModal } from './modals/generate-user-cert-modal';
import { ImportUserCertModal } from './modals/import-user-cert-modal';
import CertMultipleDetailModal from '../modals/cert-multiple-detail-modal';
import { useFetchCertList, useFetchRemoveCertDetail } from 'src/common/apis/certificates/hooks';
import { InputAdornment, Select } from 'tea-component/es';
import { PAGE_SIZE_OPTIONS } from '../../../utils/common';
import { renderChainAlgorithm } from '../node-cert';
import GlossaryGuide from 'src/common/components/glossary-guide';

const { pageable, autotip } = Table.addons;

const CERT_TYPE_MAP: {
  [index: number]: string;
} = {
  0: '根证书',
  1: 'ca证书',
  2: '用户admin证书',
  3: '用户client证书',
  4: '共识节点',
  5: '同步节点',
  6: 'light',
};

const searchOptions = [
  {
    text: '用户名称',
    value: 'UserName',
  },
  { text: '组织名称', value: 'OrgName' },
];

export function UserCert() {
  const [generateModalVisible, setGenerateModalVisible] = useState<boolean>(false);
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [targetCert, setTargetCert] = useState<ICertItem | null>(null);
  const [pageQuery, setPageQuery] = useState<Required<PagingQuery>>({ pageSize: 10, pageIndex: 1 });
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchField, setSearchField] = useState<string>('UserName');
  const [searchValueInput, setSearchValueInput] = useState<string>('');
  const { list, totalCount, fetch } = useFetchCertList();
  const { fetchRemoveCert } = useFetchRemoveCertDetail();

  const defaultColumns: TableColumn<ICertItem>[] = [
    {
      key: 'UserName',
      header: '用户名称',
    },
    {
      key: 'OrgName',
      header: '所属组织',
    },
    {
      key: 'CertType',
      header: '用户角色',
      render: ({ CertType }) => CERT_TYPE_MAP[CertType],
    },
    {
      key: 'Algorithm',
      header: '密码算法',
      render: renderChainAlgorithm,
    },
    {
      key: 'Addr',
      header: <GlossaryGuide title="链账户地址" />,
    },
    {
      key: 'CreateTime',
      header: '创建时间',
      render: ({ CreateTime }) => formatDate(CreateTime),
    },
    {
      key: 'Action',
      header: '操作',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setTargetCert(record);
              setDetailModalVisible(true);
            }}
          >
            查看
          </Button>

          <Button
            type="link"
            onClick={() => {
              fetchDeleteCert({ CertId: record.Id });
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ];

  const fetchCertList = useCallback(() => {
    fetch({
      Type: 2,
      [searchField]: searchValue ? searchValue : '',
      PageNum: pageQuery.pageIndex - 1,
      PageSize: pageQuery.pageSize,
      ChainMode: 'permissionedWithCert',
    });
  }, [fetch, searchValue, pageQuery, searchField]);

  const fetchDeleteCert = useCallback(
    (param: IDetailCertParam) => {
      fetchRemoveCert(param).then(() => {
        fetchCertList();
      });
    },
    [fetchCertList],
  );

  useEffect(() => {
    fetchCertList();
  }, [fetchCertList]);
  return (
    <>
      <GenerateUserCertModal
        visible={generateModalVisible}
        close={() => setGenerateModalVisible(false)}
        successCallback={() => {
          fetchCertList();
          setGenerateModalVisible(false);
        }}
      />
      <ImportUserCertModal
        visible={importModalVisible}
        close={() => setImportModalVisible(false)}
        successCallback={() => {
          fetchCertList();
          setImportModalVisible(false);
        }}
      />
      <CertMultipleDetailModal
        zipName={targetCert?.UserName}
        visible={detailModalVisible}
        CertType={2}
        CertId={targetCert?.Id}
        close={() => setDetailModalVisible(false)}
      />
      <Justify
        left={
          <>
            <Button type="primary" onClick={() => setGenerateModalVisible(true)}>
              申请用户证书
            </Button>
            <Button type="primary" onClick={() => setImportModalVisible(true)}>
              导入用户证书
            </Button>
          </>
        }
        right={
          <InputAdornment
            before={
              <Select
                options={searchOptions}
                defaultValue="UserName"
                style={{ width: 'auto' }}
                onChange={setSearchField}
              />
            }
          >
            <SearchBox
              placeholder="请输入关键词"
              size="l"
              value={searchValueInput}
              onChange={(value) => setSearchValueInput(value)}
              onSearch={(value) => {
                setSearchValue(value ?? '');
                setPageQuery({
                  ...pageQuery,
                  pageIndex: 1,
                });
              }}
              onClear={() => {
                setSearchValue('');
                setPageQuery({
                  ...pageQuery,
                  pageIndex: 1,
                });
              }}
            />
          </InputAdornment>
        }
      />
      <Card className="tea-mt-5n">
        <Table
          records={list}
          recordKey={(record) => `${record.Id}--${record.CertUse}`}
          columns={defaultColumns}
          addons={[
            pageable({
              recordCount: totalCount,
              pageIndex: pageQuery.pageIndex,
              pageSize: pageQuery.pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              onPagingChange: ({ pageIndex, pageSize }) =>
                setPageQuery({ pageIndex: pageIndex ?? 1, pageSize: pageSize ?? 10 }),
            }),
            autotip({
              emptyText: '暂无数据',
              isFound: !!searchValue.length,
              onClear: () => {
                setSearchValue('');
                setSearchValueInput('');
              },
            }),
          ]}
        />
      </Card>
    </>
  );
}
