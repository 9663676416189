/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback } from 'react';
import { Button, Form, Input, List, Modal, Text } from 'tea-component';
import { Controller, useForm } from 'react-hook-form';
import formUtils from '../../../utils/form-utils';
import { fetchConnectExplorer } from 'src/common/apis/chains';
import { useDispatchMessage } from 'src/utils/hooks';

type Props = { visible: boolean; onClose: () => void; ChainId: string };

export function ConnectExplorerModel(props: Props) {
  const { visible } = props;
  const {
    control,
    // reset,
    formState: { isValidating, isSubmitted, isValid },
    getValues,
  } = useForm({ mode: 'onBlur' });
  const { errorNotification } = useDispatchMessage();

  const onClose = useCallback(() => {
    props.onClose();
  }, []);
  const onSubmit = useCallback(() => {
    const url = getValues('url');
    fetchConnectExplorer({
      ExplorerUrl: url,
      ChainId: props.ChainId,
    })
      .then((res) => {
        console.log(res);
        props.onClose();
      })
      .catch((e) => {
        if (e.Message) {
          errorNotification({
            description: e.Message,
          });
        }
      });
    // await run({
    //   ...(getValues() as ChainReConfigRequest),
    //   ChainId: props.chainDetail.ChainId,
    // });
  }, []);

  return (
    <Modal visible={visible} caption="连接区块链浏览器" onClose={onClose}>
      <Modal.Body>
        <Form>
          <Controller
            control={control}
            rules={{
              required: '请输入浏览器IP和端口',
              validate: () => void 0,
            }}
            name="url"
            defaultValue=""
            render={({ field, fieldState }) => (
              <Form.Item
                required
                status={formUtils.getStatus({
                  fieldState,
                  isValidating,
                  isSubmitted,
                })}
                message={fieldState.error?.message}
                label="浏览器IP和端口"
              >
                <Input size={'full'} placeholder={'请输入浏览器IP和端口'} {...field} />
              </Form.Item>
            )}
          />
        </Form>
        <Text theme="label">示例：http://196.125.0.118:8080</Text>
        <div style={{ marginTop: 20 }}></div>
        <Text style={{ fontSize: 14 }}>通过此处可把当前管理台上的链，订阅到长安链区块链浏览器上，操作前，请先确保</Text>
        <div style={{ marginTop: 8 }}></div>
        <List type="number">
          <List.Item>您的区块链浏览器已经正常部署，且网络通畅。</List.Item>
          <List.Item>区块链浏览器上，未订阅相同ChainID的长安链。</List.Item>
        </List>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" onClick={onSubmit} disabled={!isValid}>
          确定
        </Button>
        <Button type="weak" onClick={onClose}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
