/*
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 */
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input, Modal } from 'tea-component';
import formUtils, { Validator } from 'src/utils/form-utils';
import { useFetchGenerateCert } from 'src/common/apis/certificates/hooks';
import { renderChainAlgorithmFormItem, renderChainCertTypeFormItem } from './import-public-modal';
import { ChainAlgorithm, ICertItem } from 'src/common/apis/certificates/interface';

const { getStatus } = formUtils;

interface IGenerateCertModalParam {
  visible: boolean;
  close: () => void;
  successCallback?: () => void;
}

interface IInitialData {
  RemarkName: string;
  CertType: string;
  Algorithm: string;
}

const initialData: IInitialData = {
  RemarkName: '',
  CertType: '1',
  Algorithm: ChainAlgorithm.NOT_CN.toString(),
};

export function GeneratePublicModal({ visible, close, successCallback }: IGenerateCertModalParam) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValidating, isSubmitted, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      CertType: '1',
      Algorithm: ChainAlgorithm.NOT_CN.toString(),
    } as any,
  });
  const { fetch } = useFetchGenerateCert();

  const submit = async (values: IInitialData) => {
    await fetch({
      ...values,
      CertType: Number(values.CertType),
      Algorithm: +values.Algorithm as ICertItem['Algorithm'],
      ChainMode: 'public',
    });
    successCallback?.();
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);
  return (
    <Modal caption="申请账户" visible={visible} onClose={close}>
      <Modal.Body>
        <Form>
          <Controller
            name="RemarkName"
            control={control}
            defaultValue={initialData.RemarkName}
            rules={{
              validate: (RemarkName) => Validator.RemarkName(RemarkName),
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label="账号备注名"
                required
                status={isValidating ? 'validating' : getStatus({ fieldState, isSubmitted, isValidating })}
                message={errors.RemarkName?.message}
              >
                <Input placeholder="请输入链账户名称" size="full" {...field} />
              </Form.Item>
            )}
          />
          {renderChainCertTypeFormItem(control, isSubmitted, isValidating)}
          {renderChainAlgorithmFormItem(control, isSubmitted, isValidating)}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={!isValid} onClick={handleSubmit(submit)}>
          确定
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}
