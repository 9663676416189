/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { useCallback, useState } from 'react';
import {
  fetchCreateAccount,
  fetchDisableAccount,
  fetchEnableAccount,
  fetchModifyPassword,
  fetchResetPassword,
  fetchUserList,
} from 'src/common/apis/accounts';
import { IAccountStateParam, ICreateParam, IFetchUserListParam, IModifyPasswordParam, IUserItem } from './interface';
import { useDispatchMessage } from 'src/utils/hooks';
import { LocalSetting } from '../../local-setting';
import { Error } from 'src/common/interface';

/**
 * 获取用户列表
 */
export function useFetchUserList() {
  const { errorNotification } = useDispatchMessage();
  const [list, setList] = useState<IUserItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetch = useCallback(async (params: IFetchUserListParam) => {
    try {
      const res = await fetchUserList(params);
      setList(res.data.Response.GroupList);
      setTotalCount(res.data.Response.TotalCount);
    } catch (err) {
      setList([]);
      setTotalCount(0);
      errorNotification({
        title: '获取用户列表失败',
        description: (err as Error).Message,
        duration: 0,
      });
    }
  }, []);

  return {
    list,
    totalCount,
    fetch,
  };
}

/**
 * 新增用户
 */
export function useFetchCreateUser() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: ICreateParam) => {
    try {
      await fetchCreateAccount(params);
      successNotification({
        description: '新增用户成功',
      });
    } catch (err) {
      errorNotification({
        title: '新增用户失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    fetch,
  };
}

/**
 * 禁用用户
 */
export function useFetchDisableUser() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IAccountStateParam) => {
    try {
      await fetchDisableAccount(params);
      successNotification({
        description: '禁用用户成功',
      });
    } catch (err) {
      errorNotification({
        title: '禁用用户失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    fetch,
  };
}

/**
 * 启用用户
 */
export function useFetchEnableUser() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IAccountStateParam) => {
    try {
      await fetchEnableAccount(params);
      successNotification({
        description: '启用用户成功',
      });
    } catch (err) {
      errorNotification({
        title: '启用用户失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    fetch,
  };
}

/**
 * 重置密码
 */
export function useFetchResetPassword() {
  const { errorNotification, successNotification } = useDispatchMessage();
  const fetch = useCallback(async (params: IAccountStateParam) => {
    try {
      await fetchResetPassword(params);
      successNotification({
        description: '重置密码成功',
      });
    } catch (err) {
      errorNotification({
        title: '重置密码失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    fetch,
  };
}

/**
 * 修改密码
 */
export function useModifyUserPassword() {
  const { errorNotification, successNotification } = useDispatchMessage();

  const run = useCallback(async (params: IModifyPasswordParam) => {
    try {
      await fetchModifyPassword(params);
      successNotification({
        description: '修改密码成功，请重新登录',
        onClose: () => {
          LocalSetting.logout();
          window.location.href = '/login';
        },
      });
    } catch (err) {
      errorNotification({
        title: '修改密码失败',
        description: (err as Error).Message,
      });
    }
  }, []);

  return {
    run,
  };
}
