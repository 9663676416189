/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChainAlgorithm, MapValueAsType } from 'src/common/apis/certificates/interface';
import { useFetchCertNodeList, useFetchCertOrgList } from 'src/common/apis/chains/hooks';
import { flatOptions, SelectedNodes, SourceSelector, TargetSelector } from './selector';

interface NodeSelectByRoleProps {
  onChange: (nodes: any[]) => void;
  nodeRole: 0 | 1;
  algorithm: MapValueAsType<typeof ChainAlgorithm> | undefined;
  emitOrgList?: (count: number) => void;
}

// 选取不同类型节点节点
export default function NodeSelectorByRole({ onChange, algorithm, nodeRole, emitOrgList }: NodeSelectByRoleProps) {
  const { orgList: certOrgList, run: fetchOrgList } = useFetchCertOrgList();
  const { nodeList, run: fetchNodeList } = useFetchCertNodeList();
  const [selectedOrgIndex, setSelectedOrgIndex] = useState(0);
  const [selectorOptions, setSelectorOptions] = useState<SelectedNodes>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectedNodes>([]);
  const sourceSelectorRef = useRef<any>();
  useEffect(() => {
    setSelectorOptions([]);
    setSelectedOptions([]);
    onChange([]);
    setSelectedOrgIndex(0);
    sourceSelectorRef.current.clearCache();
    if (algorithm) {
      fetchOrgList('', Number(algorithm) as any, nodeRole);
    }
  }, [algorithm]);

  useEffect(() => {
    setSelectorOptions(
      certOrgList.map((item) => ({
        text: item.OrgName,
        value: item.OrgId,
        options: [],
      })),
    );
    emitOrgList?.(certOrgList.length);
    // updateSelectNode();
  }, [certOrgList]);
  useEffect(() => {
    if (selectorOptions.length && selectorOptions[selectedOrgIndex]) {
      // 避免重新点击再次请求
      if (selectorOptions[selectedOrgIndex]?.options.length === 0) {
        fetchNodeList({ OrgId: selectorOptions[selectedOrgIndex].value, NodeRole: nodeRole });
      }
    }
  }, [selectorOptions, selectedOrgIndex]);

  useEffect(() => {
    if (nodeList.length === 0 || !selectorOptions[selectedOrgIndex]) {
      return;
    }
    selectorOptions[selectedOrgIndex].options = nodeList.map((item) => ({
      text: item.NodeName,
      value: item.NodeName,
    }));
    setSelectorOptions([...selectorOptions]);
  }, [nodeList]);

  const updateSelectOptions = useCallback((nodes: SelectedNodes) => {
    setSelectedOptions(nodes);
    const value = nodes
      .filter((item) => item.options.length > 0)
      .map((item) => ({
        OrgId: item.value,
        NodeList: item.options.map((node) => ({
          NodeName: node.value,
        })),
      }));
    onChange(value);
  }, []);

  return (
    <div className={'flex'}>
      <SourceSelector
        title="选择节点"
        data={{ title: '组织名', subTitle: '节点名称', options: selectorOptions }}
        onChange={(values) => {
          updateSelectOptions(values);
        }}
        onLeftOptionClick={setSelectedOrgIndex}
        ref={sourceSelectorRef}
      />
      <TargetSelector
        title={`已选择(${flatOptions(selectedOptions).length})`}
        data={{ title: '组织名', subTitle: '节点名称', options: selectedOptions }}
        onChange={(values) => {
          updateSelectOptions(values);
          sourceSelectorRef.current.updateSelectedNodes(values);
        }}
      />
    </div>
  );
}
