/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { notification, NotificationOptions } from 'tea-component';

/**
 * 点击路由返回到目标页
 */
export function useGotoClick(path: string) {
  const history = useHistory();
  const handleBackClick = useCallback(() => history.push(path), []);
  return handleBackClick;
}

/**
 * @deprecated
 * 不推荐使用，优先下面方法，解决类型安全
 * @see useQueryParams
 */
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function buildParams() {
  const res: { [index: string]: any } = {};
  // @ts-ignore
  for (const pair of new URLSearchParams(location.search).entries()) {
    const [key, value] = pair;
    res[key] = value;
  }
  return res;
}

export function useQueryParams<T>() {
  const history = useHistory();
  const [params, setParams] = useState(buildParams() as T);
  useEffect(() => {
    const listen = history.listen(() => {
      setParams(buildParams() as T);
    });
    return () => listen();
  });
  return params;
}

export function useDispatchMessage() {
  const successNotification = (options: NotificationOptions) => {
    notification.success(options);
  };

  const errorNotification = (options: NotificationOptions) => {
    notification.error(options);
  };

  return {
    successNotification,
    errorNotification,
  };
}

export function useArrayRef() {
  const refs: any[] = [];
  return [refs];
}
