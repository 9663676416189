/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import moment from 'moment';

export function formatDate(date: number | undefined) {
  if (date === undefined || date === null) {
    return '';
  }
  return moment.unix(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateTime(date: number | undefined) {
  if (date === undefined || date === null) {
    return '';
  }
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}
