/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { AxiosResponse } from 'axios';
import {
  IAutoReportParam,
  IDownloadLogParam,
  IFetchLogListParam,
  ILogInfo,
  IPullParam,
  IReportLogParam,
} from './interface';
import { callApi } from '../../api';
import { ListResponse, ResponseOk } from '../../interface';

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E7%AE%A1%E7%90%86/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E5%88%97%E8%A1%A8.md
 * @description GetLogList 获取日志监控列表
 */
export function fetchLogList(params: IFetchLogListParam): Promise<AxiosResponse<ListResponse<ILogInfo>>> {
  return callApi('GetLogList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E7%AE%A1%E7%90%86/%E6%8B%89%E5%8F%96%E9%93%BE%E9%94%99%E8%AF%AF%E6%97%A5%E5%BF%97.md
 * @description PullErrorLog 拉取监控日志文件
 */
export function fetchPullErrorLog(params: IPullParam): Promise<AxiosResponse<ResponseOk>> {
  return callApi('PullErrorLog', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E7%AE%A1%E7%90%86/%E6%97%A5%E5%BF%97%E6%96%87%E4%BB%B6%E4%B8%8B%E8%BD%BD.md
 * @description DownloadLogFile 下载监控日志文件
 */
export function fetchDownloadLogFile(params: IDownloadLogParam): Promise<AxiosResponse<Blob>> {
  return callApi('DownloadLogFile', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E7%AE%A1%E7%90%86/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E6%96%87%E4%BB%B6%E8%87%AA%E5%8A%A8%E6%8B%89%E5%8F%96.md
 * @description AutoReportLogFile 自动拉取监控日志文件
 */
export function fetchAutoReportLogFile(params: IAutoReportParam): Promise<AxiosResponse<ResponseOk>> {
  return callApi('AutoReportLogFile', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E7%AE%A1%E7%90%86/%E6%97%A5%E5%BF%97%E7%9B%91%E6%8E%A7%E6%96%87%E4%BB%B6%E4%B8%8A%E6%8A%A5.md
 * @description ReportLogFile 上报监控日志文件
 */
export function fetchReportLogFile(params: IReportLogParam): Promise<AxiosResponse<ResponseOk>> {
  return callApi('ReportLogFile', params);
}
