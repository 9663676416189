/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import CertificatesPage from './routes/certificates';
import AccountsPage from './routes/accounts';
import SDKPage from './routes/sdk';
import ChainsPage from './routes/chains';
import Logs from './routes/logs';
import GuidePage from './routes/guide';
import FaqPage from './routes/faq';
import Public from './routes/public';

export default [
  <Route exact path="/" render={() => <Redirect to={'/certificates'} />} key="index" />,
  <Route exact path="/certificates" component={CertificatesPage} key="Certificates" />,
  <Route exact path="/public" component={Public} key="Public" />,
  <Route path="/chains" component={ChainsPage} key="Chains" />,
  <Route exact path="/accounts" component={AccountsPage} key="Accounts" />,
  <Route exact path="/sdk" component={SDKPage} key="SDK" />,
  <Route exact path="/logs" component={Logs} key="Logs" />,
  <Route exact path="/guide" component={GuidePage} key="Guide" />,
  <Route exact path="/faq" component={FaqPage} key="Faq" />,
];
